import expoConstants from 'expo-constants';

const envVars = expoConstants.manifest.extra

export const Constants = {

    // URLs ::
    BASE_URL: envVars.REACT_APP_BASE_URL,
    CUSTOMER_URL: envVars.CUSTOMER_URL,
    ADMIN2_API: envVars.REACT_APP_ADMIN2_API,
    PROFILE_API: envVars.REACT_APP_PROFILE_API,
    B2B_API: envVars.REACT_APP_B2B_URL,
    CONTEXT_API: envVars.REACT_APP_CONTEXT_API,
    SUPERSET_URL: envVars.REACT_APP_SUPERSET_URL,
    INVOICE_URL: envVars.REACT_APP_INVOICE_URL,
    CXML_URL: envVars.REACT_APP_CXML_URL,
    // SUBURLs ::

    LOGIN: "/login",
    FORGOT_PASSWORD: "/forgot-password",
    USER: "/user",
    ORGANIZATIONS: "/organizations",
    REGIONS: "/regions",
    INFO_REQUEST: "/information-request-master",
    RESET_PASSWORD: "/reset-password",
    ORGANIZATION: "/organization",
    FINANCIAL: "/financials",
    INVOICE: "/invoice",
    LEADCX: "/Leadcx"
}