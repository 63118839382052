import React, {useState} from 'react';
import { TouchableOpacity, View, StyleSheet, Text} from 'react-native';
import { Color } from '../utils/color';
import { createStyles } from 'react-native-media-queries';
import AsyncStorage from '@react-native-async-storage/async-storage'
const MenuSettings = (props) => {
    return (
    <React.Fragment>
        {props.isOpen === true && 
        <TouchableOpacity onPress={props.onClick} activeOpacity={1} style={{ backgroundColor: Color.TRANSPARENT, 
        width: '120%', height: '100%', 
        position: 'absolute', 
        top: 0, 
        left: -230, 
        paddingLeft: 230, 
        zIndex: 1
        }}>
            <View style={{ width: props.myWidth }}>
                <View style={styles.profileDropdown}>
                    <TouchableOpacity onPress={() => { props.navigation.navigate("OrganizationData") }} style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2' }}>
                        <Text>Organization Data</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => { props.navigation.navigate("InviteUsers") }} style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2' }}>
                        <Text>Invite Users</Text>
                    </TouchableOpacity>
                    {/* Simply enable when endpoint is available */}
                    {/* <TouchableOpacity onPress={() => { props.navigation.navigate("Users") }} style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2' }}>
                        <Text>Users</Text>
                    </TouchableOpacity>   */}
                    <TouchableOpacity onPress={() => { props.navigation.navigate("Profile") }} style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2' }}>
                        <Text>Profile</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => { props.navigation.navigate("Purchase") }} style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2' }}>
                        <Text>Purchase</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => { props.navigation.navigate("APISettings") }} style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2' }}>
                        <Text>API Settings</Text>
                    </TouchableOpacity>
                    {/* <TouchableOpacity onPress={() => { props.navigation.navigate("AdminSettings") }} style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2' }}>
                        <Text>Admin Settings</Text>
                    </TouchableOpacity> */}
                    <TouchableOpacity onPress={async () => {
                        await AsyncStorage.setItem('userToken', "null");
                        await AsyncStorage.setItem('organizationID', "null");
                        props.navigation.navigate('LoginScreen')
                    }} style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 15, paddingBottom: 15, borderBottomWidth: 1, borderColor: '#EBEBF2' }}>
                        <Text>Logout</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </TouchableOpacity>}
    </React.Fragment>
    );
}

const base = StyleSheet.create({
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 60,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5
    }
});
const styles = createStyles(
    base
);
export default MenuSettings;