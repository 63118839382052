import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SafeAreaView, StyleSheet, FlatList, Text, View, Image, Dimensions, TouchableOpacity, Platform, Alert } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Color } from './../../utils/color';
import MobileHeader from './../../components/MobileHeader';
import MenuSettings from '../../components/MenuSettings';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import SideMenu from './../../components/SideMenu';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import Label from './../../components/Label';
import {Button} from '@ui-kitten/components';
import { isIphoneX } from './../../utils/isIphone-x'
const HEADER_SIZE = isIphoneX() ? 100 : 70;
import { StdAlert } from './../../components/Alert.web'
import DateTimePicker from "react-native-modal-datetime-picker";
import Input from './../../components/Input.web';
import { Constants } from './../../utils/constants';
import InformationRequestList from './InformationRequestList';
import { Analytics } from '../../components/Analytics';
var moment = require('moment');



const groupDetails = [
    {
        "id": 1,
        "group_id": 1,
        "name": "All"
    }

]

export default class InformationRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: ["1", "2", "3", "4",],
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            isOpen: false,
            isOpenAge: false,
            isOpenGender: false,
            isOpenLanguage: false,
            isGroupContainDetail: false,
            isOpenLocation: false,
            isOpenLanguageContains: false,
            isGroupContains: false,


            isOpenLocationContainsFirst: false,
            isOpenLocationContainsSecond: false,
            isOpenFilter: false,
            isOpenFilterSourceFirst: false,
            isOpenFilterContainsSecond: false,
            myWidth: Platform.select({
                web: Dimensions.get("window").width - 150,
                default: Dimensions.get("window").width,
            }),
            originalWidth: Dimensions.get("window").width,
            popUpX: 0,
            popUpY: 0,
            popUpWidth: 0,
            popUpHeight: 100,


            regionsData: [],
            countryData: [],
            ageRangeData: [],
            genderData: [],
            languagesData: [],
            groupData: [],
            groupDetails: [],
            containsData: ["Contains", "Does not Contain", "Equals", "Does not Equal"],


            selectedLocationData: [],
            selectedAgeData: [],
            selectedGenderData: [],

            selectedLanguageValues: [],
            languageOperator: "Contains",

            groupName: "",
            groupID: 0,
            selectedGroupValues: [],

            locationType: "Country",
            locationOperator: "Contains",
            locationOperatorTwo: "Contains",
            selectedIndex: 0,
            sampleNo: '',
            title: '',
            description: '',

            source: [],

            fullName: "",

            isDateTimePickerVisible: false,
            startDate: "",
            endDate: "",

            requestData: {
                "ageRange": [],
                "gender": [],
                "languages": {
                    "operator": "",
                    "value": ""
                },
                "locations": {
                    "locationType": "",
                    "condition": {
                        "operator": "",
                        "value": ""
                    }
                },
                "filters": {
                    "conditionalOperator": "",
                    "source": [
                        {
                            "name": "",
                            "condition": {
                                "operator": "",
                                "value": ""
                            }
                        }
                    ]
                }
            }
        }
    }
    logWelcomeLayout(ox, oy, width, height, px, py) {

        this.setState({
            popUpX: px,
            popUpY: py,
            popUpWidth: width
        })
    }
    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })


        if (width > 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }

        this.setState(() => ({ isWide: width >= 767 }));

    };

    async componentWillMount() {

        const userToken = await AsyncStorage.getItem('userToken');
        if (userToken == "null") {
            this.props.navigation.navigate('LoginScreen');
        } else {
            this.getInformationRequests()
        }

        const fullName = await AsyncStorage.getItem('fullName');
        this.setState({
            fullName: fullName
        })
    }



    getInformationRequests =() => {
        return [];
    }

    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }
        return (
            <View onLayout={this._handleLayout} style={styles.Container}>
                <Analytics />
                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Information Request" />}
                <View style={{ flexDirection: 'row', flex: 1, }}>
                    {this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}
                    <View style={{ width: this.state.myWidth }}>
                        {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                            width: this.state.myWidth,
                            justifyContent: "center",
                            alignItems: 'center'
                        }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'), textAlign: 'center',
                                alignSelf: 'center',
                            }]}>Information Request</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../../assets/images/small_down.png")} />
                                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("./../../assets/images/profile_img.png")} /> */}

                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>

                            </TouchableOpacity>
                        </View>}
                        <MenuSettings 
                            {...this.props} 
                            isOpen={this.state.isOpen} 
                            myWidth={this.state.myWidth} 
                            onClick={() =>this.setState({ isOpen: false})} 
                        />
                        <View style={{margin:15, width: 100}}>
                            <Button size={'tiny'}   
                            onPress={() => this.props.navigation.navigate("Engage")}
                            style={{backgroundColor: Color.Orange, borderColor: Color.Orange }}>Back</Button>
                        </View>
                        <View style={{
                            padding:40,
                            zIndex:-1,
                            width: this.state.originalWidth >= 767 ? this.state.myWidth : this.state.myWidth,
                            alignSelf: "center",
                            // paddingTop: (this.state.originalWidth >= 767 ? 20 : 0),
                            // paddingBottom: (this.state.originalWidth >= 767 ? 20 : 0),
                            height: (this.state.originalWidth >= 767 ? Dimensions.get("window").height - 170 : Platform.select({
                                web: Dimensions.get("window").height - 150,
                                default: Dimensions.get("window").height - 250,
                            })),
                            // backgroundColor: "red"
                        }}>

                            <KeyboardAwareScrollView showsVerticalScrollIndicator={true}>
                                    <InformationRequestList {...this.props} />
                            </KeyboardAwareScrollView>
                        </View>
                    </View>
                </View>
            </View >
        )
    }
}

InformationRequest.navigationOptions = {
    header: null,
};
const base = StyleSheet.create({
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
        overflow: "hidden"
    },

    mobileRowAge: {
        flexDirection: 'column',
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    boxRowMain: {
        flexDirection: "row",
        alignSelf: 'flex-start',
        alignItems: "baseline",
        marginBottom: 20,
    },
    ageBtn: {
        borderWidth: 1,
        borderColor: Color.DarkGreen,
        borderRadius: 5,
        backgroundColor: Color.White,
        padding: 10,
        height: 44,
    },
    languageBtn: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 12,
        paddingBottom: 12,
        borderWidth: 1,
        borderColor: Color.DarkGreen,
        width: 304,
        borderRadius: 5,
        backgroundColor: Color.White,
    },
    containsBtn: {
        paddingLeft: 10,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12,
        borderWidth: 1,
        borderColor: Color.DarkGreen,
        width: 140,
        borderRadius: 5,
        backgroundColor: Color.White,
        marginRight: 20,
        height: 44,
        justifyContent: 'center',
    },
    sideBerLeft: {
        width: 230,
    },
    ageDropdownMain: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        position: 'absolute',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // zIndex: 999999,
    },
    genderDropdownMain: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 230,
        position: 'absolute',
        left: 128,
        top: 256,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // zIndex: 999999,
    },
    languageDropdownMain: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 304,
        position: 'absolute',
        left: 256,
        top: 319,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // zIndex: 999999,
    },
    locationDropdownMain: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 304,
        position: 'absolute',
        left: 384,
        top: 382,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // zIndex: 999999,
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // zIndex: 999999,
    },
    languageContainsDropdownMain: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 110,
        position: 'absolute',
        left: 128,
        top: 320,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // zIndex: 999999,
    },
    locationContainsFirst: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 110,
        position: 'absolute',
        left: 128,
        top: 382,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // zIndex: 999999,
    },
    locationContainsSecond: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 110,
        position: 'absolute',
        left: 256,
        top: 382,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // zIndex: 999999,
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },
    filterBtn: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        paddingBottom: 10,
        borderWidth: 1,
        borderColor: Color.DarkGreen,
        width: 100,
        borderRadius: 5,
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Color.White,
    },
    filterBoxMain: {
        width: '100%',
        marginTop: 20,
    },
    btnMain: {
        flexDirection: 'row',
        alignSelf: "flex-start",
        marginBottom: 15,
    },
    btn: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 12,
        paddingBottom: 12,
        borderWidth: 1,
        borderColor: Color.DarkGreen,
        width: 92,
        borderRadius: 5,
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Color.White,
        marginRight: 10,
    },
    listRow: {
        marginBottom: 5,
        width: '100%',
        flexDirection: 'row',
    },
    listBtn: {
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: Color.BORDERCOLOR,
        width: 92,
        borderRadius: 5,
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Color.White,
        marginRight: 10,
        height: 44,
    },
    inputBox: {
        backgroundColor: Color.White,
    },
    btnBox: {
        width: 36,
        height: 26,
        backgroundColor: Color.White,
        marginLeft: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    submitBtn: {
        backgroundColor: Color.DarkGreen,
        width: 140,
        height: 44,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: "center",
        marginBottom: 0,
    },
    submitMain: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    bgOverly: {
        position: 'absolute',
        top: 0,
        zIndex: 1,
        width: '100%',
        height: '100%',
    },
});
const styles = createStyles(
    base,
    maxWidth(991, {
        languageBtn: {
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 12,
            paddingBottom: 12,
            borderWidth: 1,
            borderColor: Color.DarkGreen,
            width: 250,
            borderRadius: 5,
            backgroundColor: Color.White,
            marginLeft: 18,
        },
        languageDropdownMain: {
            backgroundColor: 'white',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.08,
            shadowRadius: 8,
            elevation: 1,
            width: 250,
            position: 'absolute',
            left: 256,
            top: 319,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            // zIndex: 999999,
        },

    }),
    maxWidth(767, {
        mobileRowAge: {
            flexDirection: 'row',
            alignItems: "center",
            justifyContent: "space-between",
        },
        boxRowMain: {
            flexDirection: "column"
        },
        listRow: {
            marginBottom: 5,
            width: '100%',
            flexDirection: 'row',
            // justifyContent: "flex-end"
        },
        inputBox: {
            backgroundColor: Color.White,
        },
        ageDropdownMain: {
            backgroundColor: 'white',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.08,
            shadowRadius: 8,
            elevation: 1,
            position: 'absolute',
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            // zIndex: 999999,
        },
        ageBtn: {
            borderWidth: 1,
            borderColor: Color.DarkGreen,
            borderRadius: 5,
            backgroundColor: Color.White,
            padding: 10,
        },
        languageBtn: {
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 12,
            paddingBottom: 12,
            borderWidth: 1,
            borderColor: Color.DarkGreen,
            width: '100%',
            borderRadius: 5,
            backgroundColor: Color.White,
            marginLeft: 0,
            marginBottom: 10,
        },

        genderDropdownMain: {
            backgroundColor: 'white',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.08,
            shadowRadius: 8,
            elevation: 1,
            width: "70%",
            position: 'absolute',
            right: -110,
            top: 102,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            // zIndex: 999999,
        },
        languageDropdownMain: {
            backgroundColor: 'white',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.08,
            shadowRadius: 8,
            elevation: 1,
            width: '100%',
            position: 'absolute',
            left: 0,
            top: 247,
            right: 0,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            // zIndex: 999999,
        },
        locationDropdownMain: {
            backgroundColor: 'white',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.08,
            shadowRadius: 8,
            elevation: 1,
            width: 304,
            position: 'absolute',
            left: 0,
            top: 435,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            // zIndex: 999999,
        },
        languageContainsDropdownMain: {
            backgroundColor: 'white',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.08,
            shadowRadius: 8,
            elevation: 1,
            width: 110,
            position: 'absolute',
            left: 0,
            top: 194,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            // zIndex: 999999,
        },
        locationContainsFirst: {
            backgroundColor: 'white',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.08,
            shadowRadius: 8,
            elevation: 1,
            width: 110,
            position: 'absolute',
            left: 0,
            top: 330,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            // zIndex: 999999,
        },
        locationContainsSecond: {
            backgroundColor: 'white',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.08,
            shadowRadius: 8,
            elevation: 1,
            width: 110,
            position: 'absolute',
            left: 0,
            top: 382,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            // zIndex: 999999,
        },
    }),

    maxWidth(480, {
        listRow: {
            marginBottom: 5,
            width: '100%',
            flexDirection: 'row',
            justifyContent: "flex-start"
        },
    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        }
    })
);





