import { Constants } from "../utils/constants";

let admin2Url = Constants.ADMIN2_API

export const isValidSubscription = async (params) => {
  let response = await fetch(`${admin2Url}/subscription/valid`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + params.userToken
    },
    body: JSON.stringify({
      org_id: params.org_id,
      code: params.code,
      email: params.email
    })
  });
  let responseJson = await response.json();
  return responseJson.isValid;
}

export const sendSubscription = async (params) => {
  let response = await fetch(`${admin2Url}/subscription/insert`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + params.userToken
    },
    body: JSON.stringify({
      org_id: params.org_id,
      feature_id: params.feature_id,
      tr_price: params.tr_price,
      tr_country_code: params.tr_country_code,
      tr_currency_code: params.tr_currency_code
    })
  });
  return response;
}

export const getCategories = async (userToken) => {
  let response = await fetch(`${admin2Url}/feature/category/list`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + userToken
    }
  });
  let responseJson = await response.json();
  return responseJson && responseJson.category;
}

export const getFeature = async (userToken, country_code, is_active) => {
  let response = await fetch(`${admin2Url}/feature/list`, {
    method: 'POST',
    body: JSON.stringify({ country_code, is_active }),
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + userToken
    }
  });
  let responseJson = await response.json();
  return responseJson && responseJson.feature;
}
