import React, { Component } from 'react'
import { Linking, Platform, AppState, Alert } from 'react-native';
import {Helmet} from "react-helmet";

export class qrcheck extends Component {
    constructor(props) {
        super(props)
        this.state = {
            random: Math.random(),
            script: '',
            appConfig: '',
            branchId: '',
            branchName: null
          }
      }

      async componentWillMount() {
        const branchId = this.props?.navigation?.getParam('branchId')

        const branchNames = [{ "branchId": 8027, "branchName": "Iconic Ardee Mall Gurgaon" }, { "branchId": 5117, "branchName": "Iconic Gant Select City Walk Saket" }] 

        const branch = branchNames.filter(key => key.branchId == branchId);

        if(branch.length !== 0){
          this.setState({branchId: branchId, branchName: branch[0].branchName})
        } else this.setState({branchId: branchId, branchName: "Iconic Store"})
    }

    async componentDidMount(){
      if(this.state.branchName){
        this.handleRedirect(this.state.branchId, this.state.branchName)
      }
    }


    // the qr code to be generated must follow the url format https://app.cxsphere.com/#/qrcheck?branchId=6078 
    // OR dev- http://192.168.1.8:19006/#/qrcheck?branchId=6078

    async handleRedirect(branchId, branchName){

      const detectWhatsapp = (phone, text) => {
          const uri = `whatsapp://send/?phone=${encodeURIComponent(
            phone
          )}&text=${encodeURIComponent(text)}`;
        
          const onIE = () => {
            return new Promise((resolve) => {
              window.navigator.msLaunchUri(
                uri,
                () => resolve(true),
                () => resolve(false)
              );
            });
          };
        
          const notOnIE = () => {
            return new Promise((resolve) => {
              const a =
                document.getElementById("wapp-launcher") || document.createElement("a");
              a.id = "wapp-launcher";
              a.href = uri;
              a.style.display = "none";
              document.body.appendChild(a);
        
              const start = Date.now();
              const timeoutToken = setTimeout(() => {
                if (Date.now() - start > 1250) {
                  resolve(true); //whatsApp found
                } else {
                  resolve(false);
                }
              }, 1000);
        
              // triggers when something on screen changes - tab change, click outside of website area etc.
              const handleBlur = () => {
                clearTimeout(timeoutToken);
                resolve(true);
              };
              window.addEventListener("blur", handleBlur);


              a.click() //triggers opening whatsapp which resolve(true)
              return () => window.removeEventListener("blur", handleBlur);
            });
          };
        
          return window.navigator.msLaunchUri ? onIE() : notOnIE();
        };

        detectWhatsapp('15550139880', `I am glad to be at ${branchName}. I am looking for`).then(mobileBrowser =>{
          // resolve(true)
          // if(mobileBrowser){
          //     // on mobile browser, if no whatsapp installed
          //     const alertWeb = (title, description, options) => {
          //       const result = window.confirm([title, description].filter(Boolean).join('\n')) // true if ok is clicked
          //       if (result) {
          //           const confirmOption = Linking.openURL(`https://app.cxsphere.com/#/iconicDemo?branchId=${branchId}&branchName=${branchName}`)
          //           confirmOption && confirmOption.onPress()
          //       } else {
          //           const cancelOption = null
          //           cancelOption && cancelOption.onPress()
          //       }
          //     }
              
          //     alertWeb('Open Iconic Stores web chat?', '')
          
          // } else {
          //   // desktop - when resolve(false)
          //   const alertWeb = (title, description, options) => {
          //     const result = window.confirm([title, description].filter(Boolean).join('\n')) // true of clicked ok
          //     if (result) {
          //         const confirmOption = Linking.openURL(`https://app.cxsphere.com/#/iconicDemo?branchId=${branchId}&branchName=${branchName}`)
          //         confirmOption && confirmOption.onPress()
          //     } else {
          //         const cancelOption = null
          //         cancelOption && cancelOption.onPress()
          //     }
          //   }

          //   alertWeb('No WhatsApp found', 'Open web chat?')
          // }

          return
     
      })
       
    }

    


    render () {
        return (
            <div>
                <h3 style={{color: 'black', fontSize: 12, marginLeft: 20, opacity: .5, fontFamily: 'sans-serif'}}>redirect...</h3>
               {/* {this.state.script && 
               <div id="cxspherechat" className="cxspherechat" style={{position:'fixed', bottom: 0, right: 0}}>
                    <Helmet>
                    <script type='module' name='cxspherechat' async defer >{this.state.script}</script>
                    <script>
                    {` appConfig = ${this.state.appConfig}`}
                    </script>
                    </Helmet>
                </div>} */}
            </div>
        );
      }
}

export default qrcheck