import { Constants } from "../utils/constants";

export const postData = async(params)  => {
    let queryString = `country=${params.country}&`;
    queryString += `state=${params.state}&`;
    queryString += `city=${params.city}&`;
    queryString += `store_name=${params.store}&`;
    queryString += `selected_products=${params.selectedProducts}&`;
    queryString += `year=${params.year}&`;
    queryString += `month=${params.month}`
    // queryString += `to_date=${params.endDate}`


    return await fetch(
        `${Constants.CONTEXT_API}/context-retail/keywords/${params.organization_id}?${queryString}`, {
      method: 'POST',
      body: JSON.stringify({
          country: params.country,
          state: params.state,
          city:params.city,
          storeName: params.store,
          selectedProducts:params.selectedProducts,
          year: params.year,
          month: params.month,
      })
    });
}

// export const scraperData = async(params, search_id)  => {
//   return await fetch(
//       `https://orgapi.cxsphere.com/data-request`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       'authorization': 'Bearer ' + params.userToken
//     },
//     body: JSON.stringify({
//         keywords: params.keyword && params.keyword.split(','),
//         download_limit: parseInt(params.download_limit),
//         country: params.country,
//         language:params.language,
//         search_id
//     })
//   });
// }

// export const getReportData = async(options)  => {
//   const userToken = options.userToken;
//   delete options.userToken;
//   let response = await fetch(`https://contextapi.cxsphere.com/context/keywords/${options.org_id}`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         'authorization': 'Bearer ' + userToken
//     }
//     });
//   let responseJson = await response.json();  
//   return responseJson;
// }
