import { createBrowserApp } from '@react-navigation/web';
import { createSwitchNavigator, createStackNavigator } from 'react-navigation';

// import MainTabNavigator from './MainTabNavigator';

// const switchNavigator = createSwitchNavigator({
//   // You could add another route here for authentication.
//   // Read more at https://reactnavigation.org/docs/en/auth-flow.html
//   Main: MainTabNavigator,
// });
// switchNavigator.path = '';

// export default createBrowserApp(switchNavigator, { history: 'hash' });


import AuthNavigator from './AuthNavigator';

const switchNavigator = createSwitchNavigator({
  // You could add another route here for authentication.
  // Read more at https://reactnavigation.org/docs/en/auth-flow.html
  AuthNavigator: AuthNavigator,
});
switchNavigator.path = '';

// export default createBrowserApp(switchNavigator);
export default createBrowserApp(switchNavigator, { history: 'hash' });

