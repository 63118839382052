import React, { Component } from 'react'
import { SafeAreaView, StyleSheet, ScrollView, Text, View, Image, Dimensions, Platform, Alert, TouchableOpacity } from 'react-native'
import { Autocomplete, AutocompleteItem, Input } from '@ui-kitten/components';
import { Color } from './../../../utils/color';
import MobileHeader from './../../../components/MobileHeader';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import TextBox from './../../../components/textbox';
import Label from './../../../components/Label'
import Button from './../../../components/button';
import SideMenu from '../../../components/SideMenu';
import { validNumber } from './../../../utils/validation';
import { Constants } from './../../../utils/constants';
import CCJson from '../../../data/countryCodes.json'


export default class Organization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            ccode: '',
            number: "",
            address: "",
            ccodeArray: CCJson,

            isWide: Platform.select({
                web: true,
                default: false,
            }),
            token: this.props.navigation.state.params.token,
            isLoadingAccount: false,
            originalWidth: Dimensions.get("window").width,


        }
    }

    handleText = (name) => {
        return (text) => {
            this.setState({ [name]: text.replace(/\s/g, '') })
        }
    }

    renderOptionCountry = (item, index) => {
        return (
        <AutocompleteItem
        key={index}
        title={item.dial_code}
        />
        );
    };
    filter = (item, query) => item.dial_code.includes(query);
    onChangeTextCountry = (query) => {
        let q = query.toString()
        this.setState({
            ccodeArray: CCJson.filter(item => this.filter(item, q)),
            ccode: q
        })
    };

    onSelectCountry = (index) => {
        this.setState({
            ccode: this.state.ccodeArray[index].dial_code,
        })

    };



    componentDidMount() {
        // this.props.navigation.openDrawer()
    }

    validation = () => {
        var message = "";
        if (this.state.name.length == 0) {
            message = "Please enter organization name"
        } else if (this.state.ccode.length == 0) {
            message = "Please enter your country code"
        } else if (this.state.number.length == 0) {
            message = "Please enter your contact number"
        } else if (!validNumber(this.state.number)) {
            message = "Please enter valid contact number"
        } else if (!(this.state.ccode.length + this.state.number.length >= 10 && this.state.ccode.length + this.state.number.length <= 15)) {
            message = "Contact number should be between 10 and 15 digit"        
        } else if (this.state.address.length == 0) {
            message = "Please enter contact address."
        }                                                          

        return message;

    }

    createOrganization = async () => {


        let messageAlert = this.validation()

        if (messageAlert.length > 0) {
            const alertTitle = 'CXSphere'
            const alertText = messageAlert
            if (Platform.OS === 'web') {
                alert(alertText)
            } else {
                Alert.alert(alertTitle, alertText)
            }
            return;
        }

        this.setState({
            isLoadingAccount: true
        })

        try {

            let url = `${Constants.BASE_URL}/organizations`

            let response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "name": this.state.name,
                    "contactNumber": this.state.ccode + this.state.number,
                    "address": this.state.address,
                    "token": this.state.token

                }),
            });
            let responseJson = await response.json();
            this.setState({
                isLoadingAccount: false
            })

            // console.log("******** responseJson ********", responseJson)

            if (typeof responseJson.id != "undefined") {
                //this.props.navigation.navigate("InviteUsers")
                this.props.navigation.popToTop()
            } else {
                if (Platform.OS === 'web') {
                    alert("Signup Successfully.")
                } else {
                    Alert.alert("CXSphere", "Signup Successfully.")
                }
            }
        } catch (error) {
            this.setState({
                isLoadingAccount: false
            })
            // console.error("====== error =====", error);
            // if (Platform.OS === 'web') {
            //     alert("Something went wrongs.")
            // } else {
            //     Alert.alert("CXSphere", "Something went wrongs.")
            // }
        }

    }

    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;
        this.setState({
            originalWidth: width
        })
        this.setState(() => ({ isWide: width >= 767 }));
    };
    render() {
        return (
            <SafeAreaView onLayout={this._handleLayout} style={styles.Container}>
                <View style={styles.containBox}>
                    {this.state.originalWidth < 767 && <MobileHeader {...this.props} isHide={true} title="Organization" />}
                    {this.state.originalWidth >= 767 && <View style={styles.logoDesktop}>
                        <Image style={styles.welcomeImage} source={require("./../../../assets/images/logo.png")} />
                    </View>}
                    <ScrollView contentInsetAdjustmentBehavior="automatic" contentContainerStyle={{ alignItems: "center", justifyContent: 'flex-start' }} >
                        <View style={styles.boxRow}>
                            <Label Barlow_SemiBold style={styles.title}>Organization</Label>
                            <Input onChangeText={this.handleText("name")} style={styles.inputBox} placeholder="Name" />
                            <View style={{flexDirection: 'row', alignSelf:'flex-end'}}>
                                <Autocomplete
                                    placeholder='Country'
                                    style={styles.ccBox}
                                    value={this.state.ccode}
                                    onSelect={this.onSelectCountry}
                                    onChangeText={this.onChangeTextCountry}
                                    >
                                    {this.state.ccodeArray.map(this.renderOptionCountry)}
                                </Autocomplete>
                                {/* <TextBox onChangeText={this.handleText("ccode")} textStyle={styles.ccBox} placeholder="CC" /> */}
                                <Input onChangeText={this.handleText("number")} style={styles.numberBox} placeholder="Contact Number" />
                            </View>
                            <Input onChangeText={this.handleText("address")} style={styles.inputBox} placeholder="Address" />
                            <Button
                                onClick={this.createOrganization}
                                backgroundColor={Color.DarkGreen}
                                isLoading={this.state.isLoadingAccount}
                                titleColor={Color.White} title='Save' />

                            <Button
                                btnStyle={{ marginTop: 10, }}
                                onClick={() => this.props.navigation.goBack()}
                                backgroundColor={Color.DarkGreen} titleColor={Color.White} title='Back' />
                        </View>
                    </ScrollView>
                </View>
            </SafeAreaView>
        )
    }
}

Organization.navigationOptions = {
    header: null,
};

const base = StyleSheet.create({
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
    },
    logoDesktop: {
        width: '100%',
        backgroundColor: Color.White,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: '10%',
    },
    welcomeImage: {
        width: 100,
        height: 80,
        resizeMode: 'contain',
    },
    scrollContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    title: {
        fontSize: 36,
        color: Color.DarkGreen,
        textAlign: 'center',
        width: '100%',
        marginTop: 30,
        marginBottom: 50,
    },
    boxRow: {
        justifyContent: "center",
        alignItems: "center",
        width: 400,
    },
    inputBox: {
        backgroundColor: Color.White,
        width: 400,
        borderColor: 'lightgray',
        marginBottom: 5
    },
    numberBox: {
        backgroundColor: Color.White,
        width: 300,
        borderColor: 'lightgray',
        marginBottom: 5
    },
    ccBox: {
        backgroundColor: Color.White,
        width: 100,
        borderColor: 'lightgray',
        marginBottom: 5
    },
    // menuBox: {
    //     position: "absolute",
    //     backgroundColor: '#005057',
    //     width: 230,
    //     height: 120,
    //     top: 0,
    //     left: 0,
    // },
    menuBtn: {
        alignSelf: "center",
        marginTop: 10,
    },
    containBox: {
        width: '100%',
    }

});
const styles = createStyles(
    base,
    maxWidth(767, {
        boxRow: {
            width: Dimensions.get("window").width - 30,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 30,
        },
        inputBox: {
            backgroundColor: Color.TRANSPARENT,
        },
        title: {
            display: 'none',
        },
        Container: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundColor: Color.White,
        },
        logoDesktop: {
            display: 'none',
        },

    }),
    maxWidth(480, {
        boxRow: {
            width: Dimensions.get("window").width - 30,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 30,
        },
        inputBox: {
            backgroundColor: Color.White,
            width: Dimensions.get("window").width - 30,
        },
    }),
);

