import React from 'react';
import { Text, Button, Layout } from '@ui-kitten/components';

const Summary = (props) => {
  return (
    <Layout style={{padding: 20}}>
      <Text>
      Thank you for submitting the data.
      </Text>
      <Button style={{margin: 20, width: 150}} onPress={() => props.resetReport(true)}>Go</Button>
    </Layout>
  )
}

const ReviewCxResultComponents = (props) => {
  return (
    <Layout style={{margin: 30, padding: 30, width:'80%' }}>  
        <Summary {...props} />
    </Layout>
  );
};

export default ReviewCxResultComponents;