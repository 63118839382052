import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Datepicker } from '@ui-kitten/components';
import moment from 'moment';
import './style.css';


export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div style={{ width: this.props.width || '120px' }}>
                <Datepicker
                    // status='primary'
                    min={new Date(1900, 0, 0)}
                    max={new Date(2050, 0, 0)}
                    date={this.props.value ? new Date(this.props.value) : new Date()}
                    placeholder='Pick Date'
                    onSelect={nextDate => this.props.handleDayChange(nextDate)}
                />
            </div>

        );
    }
}
