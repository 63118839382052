import React, { useState, useEffect } from 'react';
import {Text, FlatList, View, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage'
import { makeFetchCall, TableCell, ColumnHeader } from './InformationRequestList';
import { Constants } from './../../utils/constants';
import { Analytics } from '../../components/Analytics';

const fetchInformationResponse = async (info_req_id) => {
    console.log("Inside fetchInformationResponse.");
    const organizationID = await AsyncStorage.getItem('organizationID');
    const userToken = await AsyncStorage.getItem('userToken');
    console.log("Finished getting userToken and organizationId from local storage.");
    let url = `${Constants.BASE_URL}/organization/${organizationID}/info-request/${info_req_id}/user-data`;
    console.log("Built the url to get info response: ", url);
    let response = await makeFetchCall(url, userToken);
    let responseJson = await response.json();
    console.log("Finished fetching the informationResponse.", JSON.stringify(responseJson));
    return responseJson;
}

export default function InformationResponse(props) {
    return (
        <>
            <Analytics />
            <InformationResponseHeader {...props} />
            <InformationResponseTable {...props} />
        </>
    );
}
function InformationResponseHeader(props) {
    return (
        <View style={{flexDirection:'row', margin:5}}>
            <TouchableOpacity accessibilityRole="button" onPress={() => props.handleInformationRequestDetails(null)}>
                <Text>Back</Text>
            </TouchableOpacity>
        </View>
    );
}
function InformationResponseTable(props) {
    const [informationResponse, setInformationResponse] = useState([]);
    console.log("Value of informationRequestId: ", props.informationRequestId);
    useEffect(() => {
        console.log("Inside useEffect for information response.");
        fetchInformationResponse(props.informationRequestId).then(res => setInformationResponse(res));
    }, [])
    return (
        <FlatList data={informationResponse} ListHeaderComponent={InformationResponseTableHeader} renderItem={({ item }) => <InformationResponseRow {...props} item={item} />} />
    );
}
function InformationResponseRow(props) {
    return (
        <View style={{ flexDirection: 'row', margin: 5 }} key={props.item.id}>
            <TableCell text={props.item.customerId} />
            <TableCell text={props.item.ageGroup} />
            <TableCell text={props.item.Gender} />
            <TableCell text={props.item.language} />
            <TableCell text={props.item.location} />
        </View>
    )
}
function InformationResponseTableHeader(props) {
    return (
        <View style={{ flexDirection: 'row', margin: 5 }}>
            <ColumnHeader title="Customer Id" />
            <ColumnHeader title="Age group" />
            <ColumnHeader title="Gender" />
            <ColumnHeader title="Language" />
            <ColumnHeader title="Location" />
        </View>
    )
}