import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  View,
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  Dimensions,
  Platform
} from 'react-native'; 
import * as Clipboard from 'expo-clipboard';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Entypo } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Color } from '../../../utils/color';
import MobileHeader from '../../../components/MobileHeader';
import MenuSettings from '../../../components/MenuSettings';
import SideMenu from '../../../components/SideMenu';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import { Card, Text as Label, Input, Button } from '@ui-kitten/components';


//Users
export default class APISettingsScreen extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
        api_key: '',
        key_view: false,
        isOpen: false,
        temp: false,
        selectedIndex: -1,
        product: "",
        dropX: 0,
        dropY: 0,
        mainWidth: 0,
        mainHeight: 0,
        isWide: false,
        originalWidth: Dimensions.get("window").width,
        myWidth: Platform.select({
          web: Dimensions.get("window").width * 0.45,
          default: Dimensions.get("window").width,
        }),
  
        navWidth: Platform.select({
          web: Dimensions.get("window").width - 150,
          default: Dimensions.get("window").width - 30,
        }),
        isOpenProfile: false,
        fullName: "",
  
        arrProducts: [{
          "id": 0,
          "name": "All"
        }],
        arrLocation: [{
          "id": 0,
          "name": "All"
        }],
        arrAccount: [{
          "id": 0,
          "name": "All"
        }],
        arrScreen: [{
          "id": 0,
          "name": "All"
        }]
      }
  }

  async componentWillMount() {

    const authToken = await AsyncStorage.getItem('authCode');
    if (authToken !== null) {
      this.setState({api_key: authToken})
    }

    const fullName = await AsyncStorage.getItem('fullName');
    this.setState({
      fullName: fullName
    })

  }
  

  componentDidMount() {
    // this.getlistDropdown()
    // this.getScreenList()
    console.log(this.state.key_view)
  }

  _handleLayout = ({ nativeEvent }) => {
    const { width } = nativeEvent.layout;

    this.setState({
      originalWidth: width
    })
    if (width >= 767) {
      this.setState({
        navWidth:
          Platform.select({
            web: width - 150,
            default: Dimensions.get("window").width - 30,
          })
      })

    } else {
      this.setState({
        navWidth:
          Platform.select({
            web: width,
            default: Dimensions.get("window").width - 30,
          })
      })
    }

    const layout = nativeEvent.layout;
    
    this.setState({
      mainWidth: layout.width,
      mainHeight: layout.height,
    })


    if (width >= 767) {
      this.setState({
        myWidth: Platform.select({
          web: layout.width * 0.45,
          default: Dimensions.get("window").width,
        })

      })
    } else {
      this.setState({
        myWidth: Platform.select({
          web: layout.width,
          default: Dimensions.get("window").width,
        })
      })
    }

    this.setState(() => ({ isWide: width >= 767 }));

  };

  render() {

    var nameC = ""
    if (this.state.fullName != "" && this.state.fullName != null) {
      const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
      nameC = allCharacters.substring(0, 2)
    }

    let listArray = []

    if (this.state.product == "screen") {
      listArray = this.state.arrScreen;
    } else if (this.state.product == "product") {
      listArray = this.state.arrProducts;
    }
    else if (this.state.product == "location") {
      listArray = this.state.arrLocation;
    }
    else if (this.state.product == "account") {
      listArray = this.state.arrAccount;
    }

    return (
      <View onLayout={this._handleLayout} style={styles.Container}>
        {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="API Settings" />}
        <View style={{ flexDirection: 'row', flex: 1, justifyContent: "center", backgroundColor: Color.WhiteLight }}>
          {this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
            <SideMenu {...this.props} />
          </View>}
          <View style={[styles.containBox, { width: this.state.navWidth }]}>
            {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, { width: this.state.navWidth, justifyContent: "center", alignItems: 'center' }]}>
              <Label Barlow_SemiBold style={[styles.title, {
                display: (this.state.originalWidth < 767 ? 'none' : 'flex'), textAlign: 'center', alignSelf: 'center',
              }]}>API Settings</Label>
              <TouchableOpacity onPress={() => {
                this.setState({
                  isOpenProfile: true
                })
              }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("../../../assets/images/small_down.png")} />
                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("../../../assets/images/profile_img.png")} /> */}
                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                  <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                </View>
              </TouchableOpacity>
            </View>}
            <MenuSettings 
                {...this.props} 
                isOpen={this.state.isOpenProfile} 
                myWidth={this.state.navWidth} 
                onClick={() =>this.setState({ isOpenProfile: false})} 
            />

            <View style={[styles.boxRow, {
              width: this.state.navWidth
            }]}>
              <View style={[styles.boxContainer, { alignSelf: 'center' }]}>

               <Card 
               disabled
                header={<Label category='h6'>My API Keys</Label>} 
                style={{ margin: 40, width: '90%', maxWidth: 800}}
                footer={<View style={{flexDirection:'row'}}></View>}>
                <View style={{width:'100%', padding:10}}>
                  <View style={{flexDirection: 'row', backgroundColor: '#efe7e1', padding: 10, marginBottom: 10, borderRadius: 3}}> 
                  <MaterialCommunityIcons name="alert-outline" size={16} color="#ff3632" style={{marginRight: 10}}/>
                  <Label category='p1' style={{fontSize: 13, color: '#ff3632',}}>
                  Your API keys should remain confidential. They could provide full control and access to all your indices and data. You should never share or use these keys for services other than the ones provided by CXSphere.
                  </Label>
                  </View>
                    <Label category='s1' style={{marginBottom:10, minWidth: 150}}>Authentication Token:</Label>
                    <View style={styles.secret}>
                        <View>
                            <TouchableOpacity
                              style={{flexDirection: "row"}}
                              onPress={()=>Clipboard.setStringAsync(this.state.api_key)}
                            >
                            <Label 
                            numberOfLines={1} category='p3'style={[styles.key, {width: (this.state.originalWidth < 767 ? 250 : 300)}]}>
                            {this.state.key_view ? this.state.api_key : "*******************************************"}
                            </Label>
                            <MaterialCommunityIcons name="content-copy" size={15} color="#6e6e6e" style={{marginLeft: 0}} />
                            </TouchableOpacity>
                        </View>
                        <TouchableOpacity
                        onPress={()=>{this.setState({key_view: !this.state.key_view})}}
                        >
                        <Entypo name={this.state.key_view ? "eye" : "eye-with-line"} size={15} color="black"/>
                        </TouchableOpacity>
                    </View>
                    <View style={{marginTop: 10, paddingTop: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Button style={{margin: 10, width: 10, opacity: 0}} onPress={() => { this.props.navigation.navigate("AdminSettings") }}>Go</Button>
                    </View>
                  </View>
                </Card>
              </View>
            </View>
          </View>
        </View>
      </View >

    )
  }
}


APISettingsScreen.navigationOptions = {
  header: null,
};

const base = StyleSheet.create({
  Container: {
    flex: 1,
  },
  boxRow: {
    width: Dimensions.get("window").width,
  },
  boxContainer: {
    alignItems: 'center',
    width: '85%'
  },
  titleBoxUserAll: {
    backgroundColor: Color.White,
    paddingTop: 18,
    paddingBottom: 18,
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 20,
  },
  logoDesktop: {
    backgroundColor: Color.White,
    height: 60,
    borderBottomWidth: 1,
    borderColor: "#005057",
  },
  font18: {
    fontSize: 18
  },
  listRow: {
    flexDirection: 'row',
    backgroundColor: "white",
    height: 50,
    borderRadius: 3,
    alignItems: "center",
    marginTop: 8
  },
  title: {
    fontSize: 30,
    color: Color.DarkGreen,
    marginTop: 0,
    marginBottom: 0,
  },
  sideBerLeft: {
    width: 230,
  },
  containBox: {
    backgroundColor: Color.WhiteLight
  },
  circle: {
    width: 12,
    height: 12,
    borderWidth: 1,
    borderRadius: 6,
    borderColor: Color.BorderColor,
    marginLeft: 25,
    marginRight: 10
  },
  usersAllowTitle: {
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
    alignSelf: "flex-start"
  },
  listHeadingEmail: {
    color: Color.DarkGrey,
    paddingTop: 10,
    paddingBottom: 10,
  },
  listHeading: {
    color: Color.DarkGrey,
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 8
  },
  ScrollViewMain: {
    height: Dimensions.get("window").height - 500,
  },
  InviteBtn: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  inviteInput: {
    fontSize: 18,
    paddingLeft: 5,
    marginBottom: 0,
    height: 40,
    borderRadius: 0,
    borderWidth: 0,
    color: 'black',
    marginLeft: 5
  },
  btnSelect: {
    height: 40,
    borderRadius: 3,
    backgroundColor: "white",
    alignItems: 'center',
    justifyContent: 'center'
  },
  profileDropdown: {
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.08,
    shadowRadius: 8,
    elevation: 1,
    width: 155,
    position: 'absolute',
    right: 20,
    top: 80,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    zIndex: 999999,
    flex: 1,
  },
  JohnTitle: {
    fontSize: 14,
    color: "#4D4F5C"
  },
  secret: {
    flex: 1,
    backgroundColor: '#e3e3e3',
    flexDirection: 'row',
    width: '100%',
    alignSelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    borderWidth: 1,
    borderColor: 'darkgray',
    borderRadius: 8,
  },
  key:{
    fontFamily: 'monospace',
    fontSize: 12,
    color: '#6e6e6e'
  }

});

const styles = createStyles(
  base,
  maxWidth(767, {
    boxRow: {
      alignItems: 'center',
      alignSelf: 'center'
    },
    InviteBtn: {
      alignSelf: 'center',
      justifyContent: 'space-between',
      paddingLeft: 0,
      flexDirection: 'row'
    },
    btnSelect: {
      height: 40,
      borderRadius: 3,
      backgroundColor: "white",
      alignItems: 'center',
      justifyContent: 'center'
    },
    usersAllowTitle: {
      fontSize: 12,
      alignSelf: "flex-start",
      marginBottom: 10,
      marginTop: 10,
    },
    font18: {
      fontSize: 14
    },
    circle: {
      width: 10,
      height: 10,
      borderWidth: 1,
      borderRadius: 6,
      borderColor: Color.BorderColor,
      marginLeft: 15,
      marginRight: 10
    },
    inviteInput: {
      fontSize: 11,
      color: Color.White,
      paddingLeft: 5,
      marginBottom: 0,
      height: 40,
      borderRadius: 0,
      borderWidth: 0,
      marginRight: 0,
      marginLeft: 0,
      color: 'black'
    },
    listRow: {
      flexDirection: 'row',
      height: 50,
      backgroundColor: "white",
      alignItems: "center",
      marginTop: 8
    },
    ScrollViewMain: {
      height: Dimensions.get("window").height - 400,
    },
    titleBoxUserAll: {
      backgroundColor: Color.White,
      paddingTop: 18,
      paddingBottom: 18,
      width: '100%',
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: 20,
      marginTop: 20,
    },
    listHeading: {
      color: Color.DarkGrey,
      paddingTop: 10,
      paddingBottom: 10,
      marginLeft: 8

    },
    listHeadingEmail: {
      color: Color.DarkGrey,
      paddingTop: 10,
      paddingBottom: 10,
    },
    Container: {
      flex: 1,
    },
    containBox: {
      width: Dimensions.get("window").width,
      backgroundColor: Color.WhiteLight

    },
   
  }),
  
  minWidth(768, {
    sideBerLeft: {
      height: '100vh'
    },

  })
);

