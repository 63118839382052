import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  SafeAreaView,
  Text,
  Image, TouchableOpacity,
  Dimensions
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Color } from '../utils/color';
import PropTypes from 'prop-types';
import Label from '../components/Label';
import Button from './button';
import { createStyles, minWidth } from 'react-native-media-queries';
import { isIphoneX } from '../utils/isIphone-x'
import { Platform } from '@unimodules/core';
import { ScrollView } from 'react-native-gesture-handler';
const HEADER_SIZE = isIphoneX() ? 135 : 70;

class SideMenuMobile extends React.Component {
  render() {
    let { title, mainStyle } = this.props
    return (
      <SafeAreaView style={styles.Container}>
        <TouchableOpacity onPress={() => {
          this.props.navigation.closeDrawer()
        }} style={[styles.menuList, { paddingBottom: 30, borderBottomColor: Color.TRANSPARENT }]}>
        </TouchableOpacity>
        <ScrollView style={styles.SidebarMain}>
          <TouchableOpacity onPress={() => {
            this.props.navigation.navigate("Dashboard")
          }} style={styles.menuList}>
            <Image style={{ height: 16, width: 16, marginLeft: 10, position: 'absolute', tintColor: "white" }} source={require("../assets/images/dashboard.png")} />
            <Label font18Large Barlow_Medium color={Color.White} style={styles.menu}>Dashboard</Label>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => {
            this.props.navigation.navigate("OrganizationData")
          }} style={styles.menuList}>
            <Image style={{ height: 21, width: 21, marginLeft: 10, position: 'absolute', tintColor: "white" }} source={require("../assets/images/Organization_Selected.png")} />
            <Label font18Large Barlow_Medium color={Color.White} style={styles.menu}>Organization Data</Label>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => {
            this.props.navigation.navigate("InviteUsers")
          }} style={styles.menuList}>
            <Image style={{ height: 21, width: 21, marginLeft: 10, position: 'absolute', tintColor: "white" }} source={require("../assets/images/Invite_User.png")} />
            <Label font18Large Barlow_Medium color={Color.White} style={styles.menu}>Invite Users</Label>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={async () => {
              await AsyncStorage.setItem('userToken', "null");
              await AsyncStorage.setItem('organizationID', "null");
              this.props.navigation.navigate('LoginScreen')
            }} style={styles.menuList}>
            <Image style={{ height: 21, width: 21, marginLeft: 10, position: 'absolute', tintColor: 'white' }} source={require("../assets/images/logout.png")} />
            <Label font18Large Barlow_Medium color={Color.White} style={styles.menu} >Logout</Label>
          </TouchableOpacity>

        </ScrollView>
      </SafeAreaView>
    );
  }
}

const base = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'transparent',

  },
  SidebarMain: {
    width: 230,
    paddingBottom: 10,
    paddingTop: 10,
    backgroundColor: Color.DarkGreen,
    position: 'absolute',
    left: 0,
    top: 100 - (Platform.OS == 'android' ? 30 : (isIphoneX() ? 0 : 30)),
    height: Dimensions.get("window").height - HEADER_SIZE - 50 - (Platform.OS == 'android' ? 0 : 0)
  },
  menuList: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 20,
    paddingBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
    color: Color.White,
    position: 'relative',
    borderBottomWidth: 1,
    borderBottomColor: Color.GreenLineColor
  },
  menu: {
    marginLeft: 20,
  },
  welcomeImage: {
    width: 100,
    height: 70,
    resizeMode: 'contain',
  },
  menuBox: {
    backgroundColor: Color.White,
    width: 230,
    height: 100,
    justifyContent: 'center',
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: "#005057"
  },
  menuBtn: {
    alignSelf: 'flex-end',
    marginTop: 10,
    marginRight: 30,
  },


});

const styles = createStyles(
  base,
  minWidth(768, {

  })
);
export default SideMenuMobile;
