import { Constants } from "../utils/constants";

export const postData = async(params)  => {
    let queryString = `country=${params.country}&`;
    queryString += `state=${params.state}&`;
    queryString += `city=${params.city}&`;
    queryString += `order_date=${params.orderDate}&`;
    queryString += `discount=${params.discount}&`;
    queryString += `unit_price=${params.unitPrice}&`;
    queryString += `unit_cost=${params.unitCost}&`;
    queryString += `quantity=${params.quantity}&`;
    queryString += `total_profit_original_values=${params.totalOriginalProfit}&`;
    queryString += `total_sales=${params.totalSales}`
    // queryString += `to_date=${params.endDate}`


    return await fetch(
        `${Constants.CONTEXT_API}/test-context-pricing/keywords/${params.organization_id}?${queryString}`, {
      method: 'POST',
      body: JSON.stringify({
          country: params.country,
          state: params.state,
          city:params.city,
          orderDate: params.orderDate,
          discount:params.discount,
          unitPrice: params.unitPrice,
          unitCost: params.unitCost,
          quantity: params.quantity,
          totalOriginalProfit: params.totalOriginalProfit,
          totalSales: params.totalSales,
      })
    }).catch((error)=>{console.log('request error')})
}

