import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SafeAreaView, StyleSheet, FlatList, Text, Platform, View, Image, Dimensions, TouchableOpacity } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Color } from './../../utils/color';
import MobileHeader from './../../components/MobileHeader';
import MenuSettings from '../../components/MenuSettings';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import SideMenu from './../../components/SideMenu';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import Label from './../../components/Label';
import { NavigationActions } from "react-navigation"
import { fontNormal } from '../../utils/theme';
var moment = require('moment');
import { Constants } from './../../utils/constants';
import { Analytics } from '../../components/Analytics';
export default class Notification extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            myWidth: Platform.select({
                web: Dimensions.get("window").width - 150,
                default: Dimensions.get("window").width,
            }),
            navWidth: Platform.select({
                web: Dimensions.get("window").width - 150,
                default: Dimensions.get("window").width - 30,
            }),
            isOpen: false,
            isPopup: false,
            notificationData: [],
            selectedItem: {},
            originalWidth: Dimensions.get("window").width,
            fullName: ""

        }
    }


    async componentWillMount() {

        const userToken = await AsyncStorage.getItem('userToken');
        if (userToken == "null") {
            this.props.navigation.navigate('LoginScreen');
        } else {
            this.getNotificationData()
        }

        const fullName = await AsyncStorage.getItem('fullName');
        this.setState({
            fullName: fullName
        })
    }

    readNotification = async (id) => {

        const userToken = await AsyncStorage.getItem('userToken');
        const organizationID = await AsyncStorage.getItem('organizationID');

        try {

            let url = `${Constants.BASE_URL}${Constants.ORGANIZATION}/${organizationID}/notification/${id}`

            let response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            });
            let responseJson = await response.json();
            if (responseJson.code === "Success") {
                this.getNotificationData()
            } else {
                console.log("==== responseJson =====")
            }

        } catch (error) {
            console.error("====== error =====", error);
        }
    }


    getNotificationData = async () => {

        const userToken = await AsyncStorage.getItem('userToken');
        const organizationID = await AsyncStorage.getItem('organizationID');

        try {
            let url = `${Constants.BASE_URL}${Constants.ORGANIZATION}/${organizationID}/notifications`
            let response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            });
            let responseJson = await response.json();

            if (typeof responseJson != "undefined") {

                if (Array.isArray(responseJson)) {
                    this.setState({
                        notificationData: responseJson,
                        selectedItem: responseJson[0]
                    })

                }

            } else {
                if (Platform.OS === 'web') {
                    alert(responseJson.message)
                } else {
                    Alert.alert("CXSphere", responseJson.message)
                }
            }

        } catch (error) {
            // console.error("====== error =====", error.message);
            // if (Platform.OS === 'web') {
            //     alert("Somthing wrong. Please try again later.")
            // } else {
            //     Alert.alert("CXSphere", "Somthing wrong. Please try again later.")
            // }
        }
    }




    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })

        if (width >= 767) {

            this.setState({
                navWidth:
                    Platform.select({
                        web: width - 150,
                        default: Dimensions.get("window").width,
                    })
            })

        } else {
            this.setState({
                navWidth:
                    Platform.select({
                        web: width,
                        default: Dimensions.get("window").width,
                    })
            })
        }

        // MY Width :::


        if (width > 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }

        this.setState(() => ({ isWide: width >= 767 }));
    };

    componentDidMount() {
        this.props.navigation.closeDrawer()
    }


    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }

        return (
            <View onLayout={this._handleLayout} style={styles.Container}>
                <Analytics />
                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Customer Updates" />}
                <View style={{ flexDirection: 'row', flex: 1, }}>
                    {this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}
                    <View style={[styles.containBox, { width: this.state.myWidth }]}>
                        {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                            width: this.state.myWidth,
                            justifyContent: "center",
                            alignItems: 'center'
                        }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'), textAlign: 'center',
                                alignSelf: 'center',
                            }]}>Customer Updates</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../../assets/images/small_down.png")} />
                                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("./../../assets/images/profile_img.png")} /> */}
                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>
                            </TouchableOpacity>
                        </View>}
                        <MenuSettings 
                            {...this.props} 
                            isOpen={this.state.isOpen} 
                            myWidth={this.state.myWidth} 
                            onClick={() =>this.setState({ isOpen: false})} 
                        />

                        <View style={[styles.boxInteraction, {
                            width: Platform.select({
                                web: this.state.myWidth,
                                default: this.state.myWidth,
                            }),
                            flex: 1,

                        }]}>
                            {this.state.notificationData.length == 0 && <View style={{ flex: 1, width: '100%', justifyContent: "flex-start", alignItems: "center", paddingTop: 80 }}>
                                <Label font18Large Barlow_Medium>No Data Found</Label>
                            </View>}
                            {this.state.notificationData.length != 0 && <View style={[styles.leftBoxPart, {
                                width: (this.state.myWidth < 767 ? Platform.select({
                                    web: this.state.myWidth - 40,
                                    default: this.state.myWidth - 40,
                                }) : this.state.myWidth * 0.4)
                            }]}>
                                <FlatList
                                    showsVerticalScrollIndicator={false}
                                    data={this.state.notificationData}
                                    extraData={this.state}
                                    renderItem={({ item }) => {
                                        return (
                                            <TouchableOpacity onPress={() => {
                                                console.log("Click", item)
                                                if (item.unread) {
                                                    this.readNotification(item.id)
                                                }
                                                this.setState({
                                                    isPopup: true,
                                                    selectedItem: item
                                                })
                                            }} style={styles.boxColumn}>
                                                <Image style={{ height: 19, width: 19, position: "absolute", left: 18, top: 12, }} source={require("./../../assets/images/instagram.png")} />
                                                <View style={styles.SocialMediaNotification}>
                                                    <Label font18Large Barlow_Medium color={Color.BlackLight} style={styles.NotificationTitle} >{item.title}</Label>
                                                    <View style={styles.line}></View>
                                                    <Label font11Small Barlow_Regular color={Color.DarkGreen} >{moment(item.posted_at).format('YYYY-MM-DD hh:mm').toString()}</Label>
                                                    <View style={[styles.dotted, { backgroundColor: (item.unread == true ? 'red' : Color.DarkGreen) }]}></View>
                                                </View>
                                                <Label Barlow_Regular x12Small color={Color.LightGrey} style={styles.NotificationText}>{item.text}</Label>
                                            </TouchableOpacity>
                                        )
                                    }}
                                    keyExtractor={item => item.id}
                                />

                            </View>}
                            {(this.state.originalWidth >= 767 && this.state.notificationData.length != 0) && <View style={[styles.rightBoxPart, { width: this.state.myWidth * 0.45, marginLeft: 30, padding: 15 }]}>
                                <Label Barlow_Regular font15Large color={Color.LightGrey} style={styles.SubTitle}>{moment(this.state.selectedItem.posted_at).format('YYYY-MM-DD hh:mm').toString()}</Label>
                                <Label Barlow_Medium style={styles.SocialsMediaTitle}>{this.state.selectedItem.title}</Label>
                                <Label Barlow_Regular font15Large color={Color.LightGrey} style={styles.SocialsMediaContain}>
                                    {this.state.selectedItem.text}
                                </Label>
                            </View>}
                        </View>

                        {(this.state.myWidth < 767 && this.state.isPopup) && <View style={[styles.rightSidebar, { backgroundColor: Color.WhiteLight, width: this.state.myWidth, height: '100%', position: "absolute", top: (this.state.myWidth < 767 ? 0 : 100) }]}>
                            <TouchableOpacity
                                onPress={() => {
                                    this.setState({
                                        isPopup: false
                                    })
                                }}
                                style={{ alignSelf: "flex-end", marginRight: 20, marginTop: 15, }}><Label font15Large color={Color.DarkGreen}>Cancel</Label></TouchableOpacity>
                            <KeyboardAwareScrollView>
                                <View style={[styles.rightBoxPart, { width: this.state.myWidth - 40, margin: 20, padding: 15, }]}>
                                    <Label Barlow_Regular font15Large color={Color.LightGrey} style={styles.SubTitle}>{moment(this.state.selectedItem.posted_at).format('YYYY-MM-DD hh:mm').toString()}</Label>
                                    <Label Barlow_Medium style={styles.SocialsMediaTitle}>{this.state.selectedItem.title}</Label>
                                    <Label Barlow_Regular font15Large color={Color.LightGrey} style={styles.SocialsMediaContain}>
                                        {this.state.selectedItem.text}
                                    </Label>
                                </View>
                            </KeyboardAwareScrollView>
                        </View>}


                    </View>

                </View>

            </View>

        )
    }
}

Notification.navigationOptions = {
    header: null,
};
const base = StyleSheet.create({
    Container: {
        flex: 1,
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 999999,
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    boxInteraction: {
        flexDirection: 'row',
        padding: 20,
        backgroundColor: Color.WhiteLight,
    },
    leftBoxPart: {
        backgroundColor: 'background: rgba(255, 255, 255, 0.8)',
        paddingTop: 10,
        paddingBottom: 10,
    },
    rightBoxPart: {
        backgroundColor: Color.White,
    },
    SocialsMediaTitle: {
        color: Color.BlackLight,
        fontSize: 30,
        borderBottomWidth: 1,
        borderColor: Color.BORDERCOLOR,
        paddingBottom: 8,
    },
    TeamTitle: {
        fontSize: 20,
        color: Color.LightBlack,
        paddingTop: 20,
    },
    SocialsMediaContain: {
        marginTop: 10,
        paddingRight: 25,
    },
    boxColumn: {
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: Color.White,
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 12,
        paddingTop: 12,
        position: "relative"
    },
    SocialMediaNotification: {
        flexDirection: 'row',
        alignItems: "center",
        paddingLeft: 75,
        marginBottom: 12,
    },
    // NotificationTitle: {
    //     fontSize: 20,
    // },
    NotificationText: {
        paddingLeft: 75,
    },
    line: {
        width: 1,
        height: 11,
        marginLeft: 20,
        marginRight: 15,
        backgroundColor: Color.BorderLine,
    },
    dotted: {
        width: 9,
        height: 9,
        borderRadius: 5,
        position: 'absolute',
        right: -4,
        top: 0,
    },

    sideBerLeft: {
        width: 230,
    },
    rightSidebar: {
        left: 0,
    }


});
const styles = createStyles(
    base,
    maxWidth(1400, {
        SocialMediaNotification: {
            flexDirection: 'row',
            alignItems: "center",
            paddingLeft: 50,
            marginBottom: 12,
        },
        NotificationText: {
            paddingLeft: 50,
        },
    }),
    maxWidth(1200, {
        SocialMediaNotification: {
            flexDirection: 'row',
            alignItems: "center",
            paddingLeft: 35,
            marginBottom: 12,
        },
        NotificationText: {
            paddingLeft: 35,
        },
    }),
    maxWidth(1024, {
        SocialMediaNotification: {
            flexDirection: 'row',
            alignItems: "center",
            paddingLeft: 35,
            marginBottom: 12,
        },
        NotificationText: {
            paddingLeft: 35,
        },
    }),
    // maxWidth(991, {
    //     title: {
    //         fontSize: 36,
    //         color: Color.DarkGreen,
    //         textAlign: 'left',
    //         marginLeft: 10,
    //         width: '100%',
    //         marginTop: 20,
    //     },
    //     boxColumn: {
    //         width: 160,
    //         height: 160,
    //         alignItems: "center",
    //         backgroundColor: Color.White,
    //         marginLeft: 8,
    //         marginRight: 8,
    //         borderRadius: 5,
    //         shadowColor: '#000',
    //         shadowOffset: { width: 0, height: 2 },
    //         shadowOpacity: 0.50,
    //         shadowRadius: 5,
    //         elevation: 5,
    //         marginBottom: 15,
    //     },
    //     boxIcon: {
    //         height: 62,
    //         width: 62,
    //         marginBottom: 25,
    //         marginTop: 35,
    //     },
    // }),
    maxWidth(767, {
        rightSidebar: {
            top: 0
        },
        SocialMediaNotification: {
            flexDirection: 'row',
            alignItems: "center",
            paddingLeft: 35,
            marginBottom: 8,
        },
        NotificationText: {
            paddingLeft: 35,
        },
        line: {
            width: 1,
            height: 11,
            marginLeft: 10,
            marginRight: 10,
            backgroundColor: Color.BorderLine,
        },
    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        },

    })

);





