import React, { Component } from 'react'
import { SafeAreaView, StyleSheet, FlatList, Text, View, 
    Image, Dimensions, TouchableOpacity, Platform} from 'react-native'
    import AsyncStorage from '@react-native-async-storage/async-storage';
import { DataTable } from 'react-native-paper';
import TextBox from '../../components/textbox';
import { Color } from '../../utils/color';
import MobileHeader from '../../components/MobileHeader';
import MenuSettings from '../../components/MenuSettings';
import SideMenu from '../../components/SideMenu';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import Label from './../../components/Label';
import { ScrollView } from 'react-native-gesture-handler';
import Button from '../../components/button';
import { Button as TinyButton } from '@ui-kitten/components'
import { Modal, TouchableHighlight } from 'react-native';
var moment = require('moment');
import {Picker} from '@react-native-picker/picker';    
import {fetchProduct } from '../../services/ProductService';
import { Analytics } from '../../components/Analytics';
export default class Product extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            start:0,
            numberofRows: 10,
            search_key :'',
            search_value:'',
            selectedRow: 0,
            modalVisible: false,
            searchHeader: null,
            sortName: null,
            sortColumn: 0,
            sortType: false,
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            myWidth: Dimensions.get("window").width - 730,
            originalWidth: Dimensions.get("window").width,
            fullName:null,
            products: {
                header:[],
                content:[]
            }
        }
    }

     
    async componentWillMount() {

        fetchProduct(this.state.start, this.state.numberofRows, 
            this.state.search_key, 
            this.state.search_value).then(arg => this.setState({
            products: arg
        }))

        const fullName = await AsyncStorage.getItem('fullName');

        this.setState({
            fullName
        })

    }

    searchRecords =  () => {
        fetchProduct(this.state.start, this.state.numberofRows, 
            this.state.search_key, 
            this.state.search_value).then(arg => this.setState({
            products: arg
        }))
    }

    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })

        if (width >= 767) {

            this.setState({
                navWidth:
                    Platform.select({
                        web: width - 150,
                        default: Dimensions.get("window").width,
                    })
            })

        } else {
            this.setState({
                navWidth:
                    Platform.select({
                        web: width,
                        default: Dimensions.get("window").width,
                    })
            })
        }

        // MY Width :::


        if (width > 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }

        this.setState(() => ({ isWide: width >= 767 }));
    };

    setItems = (visible, rowIndex) => {
        this.setState({
            modalVisible: visible,
            selectedRow: rowIndex
        })
    }
  
    _addToRow = (element, index) => { 
        const rows = [];
        for(let x in element) {
           if(!(x === 'store_id' || x=== 'cxs_product_id' || x === 'organization_id')) { 
                rows.push(<DataTable.Cell style={{ justifyContent:'space-evenly', flex:3}} onPress={() => this.setItems(true, index)}> 
                <Text style={{
                    fontFamily: 'Barlow-Regular', fontSize:12
                }}>{ element[x] } </Text> </DataTable.Cell>);
            }
        }
        return rows;
    }

    decideSort(key, sortName) {
        if(this.state.sortColumn === key) {
            this.setState({
                sortType : this.state.sortType === 'ascending' ? 'decending' : 'ascending',
                sortColumn: key
            })
        } else {
            this.setState({
                sortType : 'ascending',
                sortColumn: key
            })
        }

        this.setState({
            sortName : sortName.toLowerCase().replace(' ', '_')
        })
    }

    sortByProperty(property) {
        if(this.state.sortType === 'ascending') { 
            return function(a,b){  
            if(a[property] > b[property])  
                return 1;  
            else if(a[property] < b[property])  
                return -1;  
            return 0;  
            }
        } else { 
            return function(a,b) {  
                if(a[property] < b[property])  
                    return 1;  
                else if(a[property] > b[property])  
                    return -1;  
                return 0;  
                }
        }  
    }

    handleText = (name) => {
        return (text) => {
          this.setState({ [name]: text.replace(/\s/g, '') })
        }
      }
    
    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }

        const { products} = this.state;
        let { content } =  products;
        content.sort(this.sortByProperty(this.state.sortName))

        return (
        <>
        <View onLayout={this._handleLayout} style={styles.Container}>
        <Analytics />
        <View style={styles.centeredView}>
      { this.state.modalVisible && <Modal
        animationType="slide"
        visible={this.state.modalVisible}
        onRequestClose={() => {
          Alert.alert('Modal has been closed.');
        }}>    
        <View style={styles.centeredView}>
          <View style={styles.modalView}>  
            <TouchableHighlight
              style={{ ...styles.openButton}}
              onPress={() => {
                this.setState({ modalVisible: !this.state.modalVisible});
              }}>
              <Text style={styles.textStyle}>X</Text>
            </TouchableHighlight>
            <View style={{marginTop:10}}>
            {
              products.header.map((item, index) => {
                return (
                <View style={{marginBottom: 10}}>
                <Text style={{
                fontFamily: 'Barlow-Regular', 
                color: Color.DarkGreen, 
                fontWeight:600}}>{item}</Text>

                <Text style={{
                    fontFamily: 'Barlow-Regular', fontSize:13, marginBottom:3
                    }}> {products.content[this.state.selectedRow][item.toLowerCase().replace(' ', '_')]} </Text>
                </View>
                )
              })
            }  
            </View>
            <View>
                    <Button btnStyle={{ width: 120 }} onClick={() => {
                                this.props.navigation.navigate("ProductDetail", {
                                organization_id : products.content[this.state.selectedRow].organization_id, 
                                cxs_product_id: products.content[this.state.selectedRow].cxs_product_id})
                            }}
                            backgroundColor={Color.DarkGreen}
                            titleColor={Color.White}
                            title="Edit/Update">
                            </Button>
                    </View>
          </View>
        </View>
      </Modal> }
      </View>
                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Product" />}
                    <View style={{ flexDirection: 'row', flex: 1, }}>
                    {   this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}
                    
                    <View style={[styles.containBox, { width: this.state.myWidth + 50 }]}>
                        {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                            width: this.state.myWidth,
                            justifyContent: "center",
                            alignItems: 'center'
                        }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'),
                                textAlign: 'center',
                                alignSelf: 'center',
                            }]}>Product</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../../assets/images/small_down.png")} />
                                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("./../../assets/images/profile_img.png")} /> */}
                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>
                            </TouchableOpacity>
                        </View>}
                        <MenuSettings 
                            {...this.props} 
                            isOpen={this.state.isOpen} 
                            myWidth={this.state.myWidth} 
                            onClick={() =>this.setState({ isOpen: false})} 
                        />
                        <View style={{margin:15, width: 100}}>
                            <TinyButton size={'tiny'} 
                            onPress={() => this.props.navigation.navigate("Engage")}
                            style={{backgroundColor: Color.Orange, borderColor: Color.Orange }}>Back</TinyButton>
                        </View>
                        <View style={{flex:1, width:'98%', zIndex:-1}} > 
                        <View style={{alignSelf:'flex-end', margin:20}}>
                            <Button btnStyle={{ width: 140 }} onClick={() => {
                                this.props.navigation.navigate("Upload")
                            }}
                            backgroundColor={Color.DarkGreen}
                            titleColor={Color.White}
                            title="Upload Products">
                            </Button>
                        </View>
                        <View style={styles.queryContainer}>
                            <View style={{flex:1, flexDirection:'row'}}>                          
                            <View>
                                <Text style={styles.countText}> Results : {products.count} </Text>
                            </View>
                            <View style={{paddingLeft:20, width:'14%', paddingRight: 10 }}>
                            <Picker
                                    style={styles.textDropDownStyle}
                                    selectedValue={this.state.search_key}
                                    onValueChange={(itemValue, itemIndex) =>
                                        this.setState({search_key: itemValue})
                                    }>
                                      <Picker.Item label={''} value={''} />
                                      <Picker.Item label={'Product Name'} value={'product_name'} /> 
                                      <Picker.Item label={'Generic Name'} value={'generic_name'} />
                                      <Picker.Item label={'Description'} value={'description'} />             
                                  </Picker>
                            </View>

                            <View>
                            <TextBox  
                            value={this.state.search_value}
                            textStyle={styles.inputBox} 
                            placeholder="Search"
                            onChangeText={this.handleText("search_value")} />
                            </View>

                            <View>
                            <Button btnStyle={{ width: 120 }} onClick={() => {
                                this.searchRecords()
                            }}
                            backgroundColor={Color.DarkGreen}
                            titleColor={Color.White}
                            title="Search">
                            </Button>
                            </View>

                            <View>
                            { this.state.start > 0 && (   
                            <Button btnStyle={{ width: 120, marginLeft:10 }} onClick={() => {
                                this.setState({
                                    start: this.state.start - this.state.numberofRows
                                }, () => this.searchRecords())
                            }}
                            backgroundColor={Color.DarkGreen}
                            titleColor={Color.White}
                            title="Prev">
                            </Button>
                            )}
                            </View>
                            <View>
                            {products.count > (this.state.start + this.state.numberofRows) &&
                            <Button btnStyle={{ width: 120, marginLeft:10 }} onClick={() => {
                                this.setState({start : this.state.start + this.state.numberofRows}, () => this.searchRecords())
                            }}
                            backgroundColor={Color.DarkGreen}
                            titleColor={Color.White}
                            title="Next">
                            </Button>
                            }       
                            </View>
                        </View>    
                        </View>            
                        <View style={[styles.leftBoxPart, {
                            width: '100%',
                            alignSelf: "center",
                            borderRadius:5,
                            padding:20,
                            fontFamily: 'Barlow-Regular',
                            marginBottom: this.state.myWidth > 767 ? 20 : 0                            
                        }]}>

                    <ScrollView horizontal={false}>
                        <DataTable>
                        <DataTable.Header style={{ backgroundColor:'#eee'}}>
                         { products.header.map((item, key) => {
                            return ( <DataTable.Title 
                                sortDirection={this.state.sortColumn === key ? this.state.sortType : false }
                                onPress={() => this.decideSort(key, item)}    
                                style={{ justifyContent:'space-evenly'
                                }}>
                            <Text style={{
                                fontFamily: 'Barlow-Regular', 
                                color: Color.DarkGreen, 
                                fontWeight:600}} >{item} </Text>
                            </DataTable.Title>
                            )     
                         })} 
                        </DataTable.Header>

                        {content.map((element, key) => 
                            <DataTable.Row style={{backgroundColor:'#fff'}}>
                            {
                                this._addToRow(element, key)
                            }
                            </DataTable.Row>   
                        )}
                       </DataTable>
                    </ScrollView> 
        </View>
                        </View>        
        </View>
                </View>
            </View >
        </>
        )
    }
}

Product.navigationOptions = {
    header: null,
};
const base = StyleSheet.create({
    queryContainer:{
        backgroundColor: '#fff',
        width:'98%',
        padding: 10
    },
    inputBox: {
        backgroundColor: Color.White,
        width: 400,
        marginRight: 20
    },
    /*
    buttonContainer: {
            flex:1,
            padding:10,
            paddingRight:40,
            alignSelf:'flex-end',
            alignContent:'flex-end'
    },
    */
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
    },

  head: { backgroundColor: '#EEE' },
  countText: { fontSize:12, color: Color.DarkGrey, fontFamily: 'Barlow-Regular', 
  fontWeight:'600', paddingLeft:18, paddingRight:18 },
  text: { fontSize:12, color: Color.DarkGreen, fontFamily: 'Barlow-Regular', 
  fontWeight:'600' },
  rowText: { fontFamily: 'Barlow-Regular',  paddingLeft:18, paddingRight:18, fontSize:12 },
  row: { flexDirection: 'row', backgroundColor: '#FFFFFF', padding: 5, margin:5 },
    leftBoxPart: {
    //    backgroundColor: 'background: rgba(255, 255, 255, 0.8)',
    },
    boxColumn: {
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: Color.White,
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 12,
        paddingTop: 12,
        position: "relative"
    },
    submitBtn: {
        backgroundColor: Color.DarkGreen,
        width: 140,
        height: 44,
        borderRadius: 5,
        marginBottom: 0,
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    boxRow: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        overflow:'scroll'
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 999999,
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },
    textDropDownStyle: {
        borderWidth: 1,
        borderColor: '#DEDEDE',
        width: '100%',
        borderRadius: 5,
        fontSize: 14,
        padding: 12,
        marginBottom: 12,
        fontFamily: 'Barlow-Regular',
        outline: "none"
    },
    boxInteraction: {
    },
    centeredView: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginTop: 22,
        zIndex: 9999,
        position:'absolute',
        top:10,
        right:5
      },
      modalView: {
        width: 300,
        margin: 10,
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 35,
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        height: 400,
        overflow:'scroll'
      },
      openButton: {
        backgroundColor: '#F194FF',
        borderRadius: 20,
        padding:0,
        elevation: 2,
      },
      textStyle: {
        color: 'black',
        fontWeight: 'bold',
        textAlign: 'right',
      }

});
const styles = createStyles(
    base,
    maxWidth(767, {
        boxRow: {
            width: Dimensions.get("window").width - 30,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 30,
        },

        Container: {
            flex: 1,
            backgroundColor: Color.White,
        },
        containBox: {
            width: Dimensions.get("window").width,
        }
    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        },
    })
);
