// It file contain all color constant used in whole app

export const Color = {
    WhiteLight: '#F1F1F1',
    White: "#FFFFFF",
    Black: "#000000",
    BlackLight: "#231F20",
    TRANSPARENT: "transparent",
    Blue: "#007AAA",
    Orange: "#F34A38",
    DarkGrey: "#595A5A",
    LightGrey: "#959596",
    DarkBlack: "#2B190C",
    DarkGreen: "#005961",
    DISBLE_TAB: "#707070",
    BORDERCOLOR: "#DEDEDE",
    GreenLineColor: "#1A6A71",
    LightBlack: "#363636",
    BorderLine: '#A7A7A7',

    /* Text color & Background color */

};
