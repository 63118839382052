import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  View,
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  Dimensions,
  Platform
} from 'react-native'; 
import * as Clipboard from 'expo-clipboard';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Entypo } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Color } from '../../../utils/color';
import MobileHeader from '../../../components/MobileHeader';
import MenuSettings from '../../../components/MenuSettings';
import SideMenu from '../../../components/SideMenu';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import { Card, Text as Label, Input, Button } from '@ui-kitten/components';
import LabelB from '../../../components/Label';
import Iframe from 'react-iframe'
// import Iframe from '@nicholasadamou/react-iframe'
import Cookies from 'js-cookie';

export default class AdminSettingsScreen extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
        authCode: '',
        myHtml: '',
        key_view: false,
        isOpen: false,
        temp: false,
        selectedIndex: -1,
        product: "",
        dropX: 0,
        dropY: 0,
        mainWidth: 0,
        mainHeight: 0,
        isWide: false,
        originalWidth: Dimensions.get("window").width,
        myWidth: Platform.select({
          web: Dimensions.get("window").width,
          default: Dimensions.get("window").width,
        }),
  
        navWidth: Platform.select({
          web: Dimensions.get("window").width,
          default: Dimensions.get("window").width - 30,
        }),
        isOpenProfile: false,
        fullName: "",
  
        arrProducts: [{
          "id": 0,
          "name": "All"
        }],
        arrLocation: [{
          "id": 0,
          "name": "All"
        }],
        arrAccount: [{
          "id": 0,
          "name": "All"
        }],
        arrScreen: [{
          "id": 0,
          "name": "All"
        }]
      }
  }

  async componentWillMount() {
    const authCode = await AsyncStorage.getItem('authCode');
    if (authCode !== null) {
      this.setState({authCode: authCode})
      //Cookies.set('session', "eyJ0b2tlbiI6ICJleUpwWkNJNk1qRjkuWmdWRzlnLjdSMk5FRlFuTmdZeFAxWW1QeFVmRExaR2htayIsICJvcmdfaWQiOiAyfQ==.ZgVG-w.BjBFKkEHz80WXY8rC7YuPcE5ArU", {domain:"cxml.cxsphere.com"})
    }

    const fullName = await AsyncStorage.getItem('fullName');
    this.setState({
      fullName: fullName
    })

    // use the api_key to get html of logged in admin panel and supply it to the iframe. Any futher request will be handled
    // by using the stored cookies in production
    const myHeaders = new Headers();
    myHeaders.append("Authorization", this.state.authCode);

    const urlencoded = new URLSearchParams();

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: urlencoded,
      redirect: "follow",
      // credentials: 'include',
    };

    fetch("https://cxml.cxsphere.com/admin/login", requestOptions)
      .then((response) => response.text())
      .then((result) => this.setState({myHtml: result}))
      .catch((error) => console.error(error));
  }
  

  componentDidMount() {
    // this.getlistDropdown()
    // this.getScreenList()
    // console.log(this.state.key_view)
    // let value = Cookies.get('session') // => 'value'
    // console.log('cookie Value',value)
  }

  _handleLayout = ({ nativeEvent }) => {
    const { width } = nativeEvent.layout;

    this.setState({
      originalWidth: width
    })
    if (width >= 767) {
      this.setState({
        navWidth:
          Platform.select({
            web: width - 150,
            default: Dimensions.get("window").width - 30,
          })
      })

    } else {
      this.setState({
        navWidth:
          Platform.select({
            web: width,
            default: Dimensions.get("window").width - 30,
          })
      })
    }

    const layout = nativeEvent.layout;
    
    this.setState({
      mainWidth: layout.width,
      mainHeight: layout.height,
    })


    if (width >= 767) {
      this.setState({
        myWidth: Platform.select({
          web: layout.width * 0.45,
          default: Dimensions.get("window").width,
        })

      })
    } else {
      this.setState({
        myWidth: Platform.select({
          web: layout.width,
          default: Dimensions.get("window").width,
        })
      })
    }

    this.setState(() => ({ isWide: width >= 767 }));

  };

  // setAuth=async()=>{

  //   Cookies.set('session', this.state.api_key, {domain:".cxsphere.com"})
  //   let value = Cookies.get('session') // => 'value'

  //   console.log('cookie Value',value)

  // }

  render() {

    var nameC = ""
    if (this.state.fullName != "" && this.state.fullName != null) {
      const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
      nameC = allCharacters.substring(0, 2)
    }

    let listArray = []

    if (this.state.product == "screen") {
      listArray = this.state.arrScreen;
    } else if (this.state.product == "product") {
      listArray = this.state.arrProducts;
    }
    else if (this.state.product == "location") {
      listArray = this.state.arrLocation;
    }
    else if (this.state.product == "account") {
      listArray = this.state.arrAccount;
    }

    return (
      <View onLayout={this._handleLayout} style={styles.Container}>
        {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="API Settings" />}
        <View style={{ flexDirection: 'row', flex: 1, justifyContent: "center", backgroundColor: Color.WhiteLight }}>
          {this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
            <SideMenu {...this.props} />
          </View>}
          <View style={[styles.containBox, { width: this.state.navWidth }]}>
            {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, { width: this.state.navWidth, justifyContent: "center", alignItems: 'center' }]}>
              <Label Barlow_SemiBold style={[styles.title, {
                display: (this.state.originalWidth < 767 ? 'none' : 'flex'), textAlign: 'center', alignSelf: 'center',
              }]}>Admin Settings</Label>
              <TouchableOpacity onPress={() => {
                this.setState({
                  isOpenProfile: true
                })
              }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("../../../assets/images/small_down.png")} />
                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("../../../assets/images/profile_img.png")} /> */}
                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                  <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                </View>
              </TouchableOpacity>
            </View>}
            <MenuSettings 
                {...this.props} 
                isOpen={this.state.isOpenProfile} 
                myWidth={this.state.navWidth} 
                onClick={() =>this.setState({ isOpenProfile: false})} 
            />

            <View style={[styles.boxRow, {
              width: this.state.navWidth
            }]}>
              <View style={[styles.boxContainer, { alignSelf: 'center' }]}>

                 {/* this was is to never show log in screen even the first time */}
              {/* <iframe
               srcDoc={this.state.myHtml}
               height={this.state.mainHeight}
               width="100%"
               allowFullScreen
               frameBorder={0}
               /> */}

              {/* in production: logs in if cxml has been logged in seperately or will show login screen */}
                {/* <Iframe
                  // url={`https://cxml.cxsphere.com/login?access_token=${this.state.api_key}&redirect=cxml.cxsphere.com/admin`}
                  url={`https://cxml.cxsphere.com/admin`}
                  position="fixed"
                  allowfullscreen
                  width='75%'
                  height={'100%'}
                  frameBorder={0}
                  headers={{
                    Authorization: `Bearer ${this.state.authCode}`,
                   }}
                /> */}

                <Iframe
                          src={`https://cxml.cxsphere.com/admin/prompt/list`}
                          width={'95%'}
                          height={600}
                          frameBorder={0}
                          headers={{
                            'Authorization': `eyJpZCI6MjF9.ZV9phw.ciF-TuKteRu4K-CPRgj2fACZ0l8`,
                          }}
                      />
               
              </View>
            </View>
          </View>
        </View>
      </View >

    )
  }
}


AdminSettingsScreen.navigationOptions = {
  header: null,
};

const base = StyleSheet.create({
  Container: {
    flex: 1,
  },
  boxRow: {
    width: Dimensions.get("window").width,
  },
  boxContainer: {
    alignItems: 'center',
    width: '85%'
  },
  titleBoxUserAll: {
    backgroundColor: Color.White,
    paddingTop: 18,
    paddingBottom: 18,
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 20,
  },
  logoDesktop: {
    backgroundColor: Color.White,
    height: 60,
    borderBottomWidth: 1,
    borderColor: "#005057",
  },
  font18: {
    fontSize: 18
  },
  listRow: {
    flexDirection: 'row',
    backgroundColor: "white",
    height: 50,
    borderRadius: 3,
    alignItems: "center",
    marginTop: 8
  },
  title: {
    fontSize: 30,
    color: Color.DarkGreen,
    marginTop: 0,
    marginBottom: 0,
  },
  sideBerLeft: {
    width: 230,
  },
  containBox: {
    backgroundColor: Color.WhiteLight
  },
  circle: {
    width: 12,
    height: 12,
    borderWidth: 1,
    borderRadius: 6,
    borderColor: Color.BorderColor,
    marginLeft: 25,
    marginRight: 10
  },
  usersAllowTitle: {
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
    alignSelf: "flex-start"
  },
  listHeadingEmail: {
    color: Color.DarkGrey,
    paddingTop: 10,
    paddingBottom: 10,
  },
  listHeading: {
    color: Color.DarkGrey,
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 8
  },
  ScrollViewMain: {
    height: Dimensions.get("window").height - 500,
  },
  InviteBtn: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  inviteInput: {
    fontSize: 18,
    paddingLeft: 5,
    marginBottom: 0,
    height: 40,
    borderRadius: 0,
    borderWidth: 0,
    color: 'black',
    marginLeft: 5
  },
  btnSelect: {
    height: 40,
    borderRadius: 3,
    backgroundColor: "white",
    alignItems: 'center',
    justifyContent: 'center'
  },
  profileDropdown: {
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.08,
    shadowRadius: 8,
    elevation: 1,
    width: 155,
    position: 'absolute',
    right: 20,
    top: 80,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    zIndex: 999999,
    flex: 1,
  },
  JohnTitle: {
    fontSize: 14,
    color: "#4D4F5C"
  },
  secret: {
    flex: 1,
    backgroundColor: '#e3e3e3',
    flexDirection: 'row',
    width: '100%',
    alignSelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    borderWidth: 1,
    borderColor: 'darkgray',
    borderRadius: 8,
  },
  key:{
    fontFamily: 'monospace',
    fontSize: 12,
    color: '#6e6e6e'
  }

});

const styles = createStyles(
  base,
  maxWidth(767, {
    boxRow: {
      alignItems: 'center',
      alignSelf: 'center'
    },
    InviteBtn: {
      alignSelf: 'center',
      justifyContent: 'space-between',
      paddingLeft: 0,
      flexDirection: 'row'
    },
    btnSelect: {
      height: 40,
      borderRadius: 3,
      backgroundColor: "white",
      alignItems: 'center',
      justifyContent: 'center'
    },
    usersAllowTitle: {
      fontSize: 12,
      alignSelf: "flex-start",
      marginBottom: 10,
      marginTop: 10,
    },
    font18: {
      fontSize: 14
    },
    circle: {
      width: 10,
      height: 10,
      borderWidth: 1,
      borderRadius: 6,
      borderColor: Color.BorderColor,
      marginLeft: 15,
      marginRight: 10
    },
    inviteInput: {
      fontSize: 11,
      color: Color.White,
      paddingLeft: 5,
      marginBottom: 0,
      height: 40,
      borderRadius: 0,
      borderWidth: 0,
      marginRight: 0,
      marginLeft: 0,
      color: 'black'
    },
    listRow: {
      flexDirection: 'row',
      height: 50,
      backgroundColor: "white",
      alignItems: "center",
      marginTop: 8
    },
    ScrollViewMain: {
      height: Dimensions.get("window").height - 400,
    },
    titleBoxUserAll: {
      backgroundColor: Color.White,
      paddingTop: 18,
      paddingBottom: 18,
      width: '100%',
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: 20,
      marginTop: 20,
    },
    listHeading: {
      color: Color.DarkGrey,
      paddingTop: 10,
      paddingBottom: 10,
      marginLeft: 8

    },
    listHeadingEmail: {
      color: Color.DarkGrey,
      paddingTop: 10,
      paddingBottom: 10,
    },
    Container: {
      flex: 1,
    },
    containBox: {
      width: Dimensions.get("window").width,
      backgroundColor: Color.WhiteLight

    },
   
  }),
  
  minWidth(768, {
    sideBerLeft: {
      height: '100vh'
    },

  })
);


