import React, {useState, useEffect} from 'react'
import { StyleSheet, FlatList, View,ScrollView,
    Image, Dimensions, TouchableOpacity, Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Color } from '../utils/color';
import { DataTable } from 'react-native-paper';
import MobileHeader from '../components/MobileHeader';
import MenuSettings from '../components/MenuSettings';
import SideMenu from '../components/SideMenu';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import { Select, SelectItem, Input, Button, Card, Text, Autocomplete, AutocompleteItem, CheckBox } from '@ui-kitten/components';
import Label from './../components/Label';
import CountryArrayJSON from '../data/country.json';
import countryJson from '../data/countryNames.json';
import countryIsoJson from '../data/countryIso.json';
import languageJson from '../data/language.json';
import {postData, getReportData, getInfoData} from '../services/CampaignData';
import { Analytics } from '../components/Analytics';
import Iframe from 'react-iframe'

var moment = require('moment');

const  sortByValue = (jsObj) => {
    let object = {};
    for(var i in jsObj) {
        object[jsObj[i]] = countryIsoJson[i];
    }
    return object;
}

const searchJson = ['Country', 'Region', 'City'];
const sortedCountryJSON = sortByValue(countryJson);

export default class CampaignDataScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            contextData: {},
            isDownloadError: false,
            userToken:'',
            openFile : false,
            isReport: true,
            search_id:'',
            search_name:'',
            super_set_id:'',
            authCode:'',
            isSuperSet: false,
            organization_id: null,
            download_limit: null,
            myWidth: Dimensions.get("window").width - 730,
            originalWidth: Dimensions.get("window").width,
            fullName:null,
            geo_country: '',
            geo_state: '',
            regionCountry:'',
            stateCountry:'',
            cityCountry: '',
            country: '',
            languageIso: 'English',
            language: 'en',
            resolution: 'Country',
            keyword: '',
            showConfirmation: false,
            queryCountry: CountryArrayJSON,
            queryLanguage: languageJson
        }
    }
    async componentWillMount() {

        const fullName = await AsyncStorage.getItem('fullName');
        const organization_id = await AsyncStorage.getItem('organizationID');
        const authCode = await AsyncStorage.getItem('authCode');
        const userToken = await AsyncStorage.getItem('userToken');
        let contextData = await AsyncStorage.getItem('Context2Campaign');
        contextData = JSON.parse(contextData);

        this.setState({
            fullName,
            organization_id,
            authCode,
            userToken,
            keyword: contextData && contextData.search_query,
            country: contextData && contextData.country,
            search_id: contextData && contextData.search_id,
            search_name: contextData && contextData.search_name,
            isReport: contextData ? false : true
        })

    }

    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })

        if (width >= 767) {

            this.setState({
                navWidth:
                    Platform.select({
                        web: width - 150,
                        default: Dimensions.get("window").width,
                    })
            })

        } else {
            this.setState({
                navWidth:
                    Platform.select({
                        web: width,
                        default: Dimensions.get("window").width,
                    })
            })
        }

        // MY Width :::


        if (width > 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }

        this.setState(() => ({ isWide: width >= 767 }));
    };

    openSystem = () => {
        this.setState({
            openFile:true
        })
    }

    setGeoCountryIndex = index => {
        const key = Object.keys(sortedCountryJSON)[index - 1];
        const array = sortedCountryJSON[key];
        this.setState({
            geo_countryIso:array[0],
            geo_country: countryIsoJson[array[1]]
        })
    }

    setResolutionIndex = index => {
        const data = searchJson[index - 1];
        this.setState({
            resolution: data
        })
    }

    setLanguageIndex = index => {
        const data = languageJson[index - 1];
        this.setState({
            language: data.code,
            languageIso: data.name
        })
    }

    getCountryCode = (value) => {
        return sortedCountryJSON[value];
    }

    filter = (item, query) => item.toLowerCase().includes(query.toLowerCase());

    onSelectCountry = (index) => {
        this.setState({
            country: this.getCountryCode(this.state.queryCountry[index])
        })
    };
    
    onChangeTextCountry = (query) => {
        this.setState({
            queryCountry: CountryArrayJSON.filter(item => this.filter(item, query)),
            country: query
        })
    };

    renderOptionCountry = (item, index) => {
        return (
        <AutocompleteItem
        key={index}
        title={item}
        />
        );
    };

    filter = (item, query) => item.toLowerCase().includes(query.toLowerCase());

    onSelectLanguage = (index) => {
        this.setState({
            language: this.state.queryLanguage[index].code
        })
    };
    
    onChangeTextLanguage = (query) => {
        this.setState({
            queryLanguage: languageJson.filter(item => this.filter(item.name, query)),
            language: query
        })
    };

    renderOptionLanguage = (item, index) => {
        return (
        <AutocompleteItem
        key={index}
        title={item.name}
        />
        );
    };

    onSelectGeoCountry = (index) => {
        this.setState({
            geo_country: this.getCountryCode(this.state.queryCountry[index])
        })
    };
    
    onChangeTextGeoCountry = (query) => {
        this.setState({
            queryCountry: CountryArrayJSON.filter(item => this.filter(item, query)),
            geo_country: query
        })
    };

    renderOptionGeoCountry = (item, index) => {
        return (
        <AutocompleteItem
        key={index}
        title={item}
        />
        );
    };

    setSuperSetId = (super_set_id) => {
        this.setState({super_set_id, isSuperSet: true, isReport: undefined});
    }
    
    resetReport = (status) => {
        this.setState({isReport: status});
    }

    submitData = async () => {
        if (Number(this.state.download_limit) <= 1000) {
            this.setState({ isDownloadError:  true });
            return false;
        } else {
            this.setState({ isDownloadError:  false });
        }
        try {
            const response = await (await postData(this.state)).json();
            if(response.message) {
                await AsyncStorage.removeItem('Context2Campaign');
                this.setState({showConfirmation: true});
            }   
        } catch (e) {
            console.log('error', e);
        }   
    }

    // API ::: kabir cassendra system integrated with this
    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }
        return (
            <View onLayout={this._handleLayout} style={styles.Container}>
                <Analytics />
                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Campaign Details" />}
                    <View style={{ flexDirection: 'row', flex: 1, }}>
                    {   this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}
                    
                    <View style={[styles.containBox, { width: this.state.myWidth }]}>
                        {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                            width: this.state.myWidth,
                            justifyContent: "center",
                            alignItems: 'center'
                        }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'),
                                textAlign: 'center',
                                alignSelf: 'center',
                            }]}>Campaign Details</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../assets/images/small_down.png")} />
                                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("./../../assets/images/profile_img.png")} /> */}
                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>
                            </TouchableOpacity>
                        </View>}
                        <MenuSettings 
                            {...this.props} 
                            isOpen={this.state.isOpen} 
                            myWidth={this.state.myWidth} 
                            onClick={() =>this.setState({ isOpen: false})} 
                        />   
                        <View style={{margin:15, width: 100}}>
                            <Button size={'tiny'} 
                            onPress={() => this.props.navigation.navigate("Engage")}
                            style={{backgroundColor: Color.Orange, borderColor: Color.Orange }}>Back</Button>
                        </View>
                            <View style={{ alignContent:'center', alignItems:'center', justifyContent:'space-between'}}>
                            { this.state.isReport === true && 
                            <View style={{alignContent:'center', alignItems:'center', justifyContent:'space-between', width:900}}>
                            <Card 
                            header={<Text category='h6'>Submitted Campaigns</Text>} 
                            style={{ marginTop: 40, width: '100%'}}
                            >
                                <View style={{padding:5, alignItems:'flex-end'}}>
                                <Button 
                                style={{width:200}} onPress={() => this.setState({isReport: false})}>New Search</Button></View>
                                <ReportView {...this.props} setSuperSetId={this.setSuperSetId} />
                            </Card>
                            </View>  }
                            { this.state.isReport === false && <View>
                                { this.state.showConfirmation === false && <Card 
                                header={
                                <View style={{flexDirection: 'row'}}>
                                    <Text category='h5'>Provide Information
                                    <Button
                                    style={{marginLeft: 20}}
                                    status={'success'}
                                    size={'tiny'} 
                                    onPress={() => this.props.navigation.navigate("ContextData")}>Back</Button>
                                    </Text>
                                </View>} 
                                style={{ marginTop: 40, width: '150%', alignSelf: 'center', alignContent: 'center'}}
                                footer={<View>
                                    <Button 
                                    style={{width:100, alignSelf: 'center'}} onPress={() => this.submitData()}>submit</Button></View>}>    
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: "flex-start",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>Country</Label>
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'row'),
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>
                                        <Autocomplete
                                            placeholder='Select Country'
                                            value={this.state.country}
                                            onSelect={this.onSelectCountry}
                                            onChangeText={this.onChangeTextCountry}>
                                            {this.state.queryCountry.map(this.renderOptionCountry)}
                                        </Autocomplete>
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: "flex-start",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>Language</Label>
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'row'),
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>
                                        <Autocomplete
                                            placeholder='Select Language'
                                            value={this.state.language}
                                            onSelect={this.onSelectLanguage}
                                            onChangeText={this.onChangeTextLanguage}>
                                            {this.state.queryLanguage.map(this.renderOptionLanguage)}
                                        </Autocomplete>
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: "flex-start",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                        }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>Group By</Label>
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'row'),
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>

                                        <Select 
                                            style={{width: 200}}
                                            value={this.state.resolution}
                                            placeholder={'Choose Resolution'}
                                            onSelect={index => this.setResolutionIndex(index)}>
                                            {searchJson.map((item) => <SelectItem title={item} />)}        
                                        </Select>
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-start',
                                        alignItems: "flex-start",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                        }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}></Label>
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'row'),
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>

                                        { this.state.resolution === 'Region' && <Autocomplete
                                            placeholder='Select Country'
                                            value={this.state.geo_country}
                                            onSelect={this.onSelectGeoCountry}
                                            onChangeText={this.onChangeTextGeoCountry}>
                                            {this.state.queryCountry.map(this.renderOptionGeoCountry)}
                                        </Autocomplete>}
                                        <View style={{flexDirection:'column'}}>
                                        { this.state.resolution === 'City' && <Autocomplete
                                            placeholder='Select Country'
                                            value={this.state.geo_country}
                                            onSelect={this.onSelectGeoCountry}
                                            onChangeText={this.onChangeTextGeoCountry}>
                                            {this.state.queryCountry.map(this.renderOptionGeoCountry)}
                                        </Autocomplete>}
                                        { this.state.resolution === 'City' && <Input
                                            style={{marginTop: 20}}
                                            placeholder='Please Suggest a State'
                                            value={this.state.geo_state}
                                            onChangeText={geo_state => this.setState({geo_state})}
                                        />}
                                        </View>
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-srart',
                                        alignItems: "flex-srart",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>Keyword</Label>
                                        <View style={{
                                            flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'row'),
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>
                                             <Input
                                                placeholder='Keyword'
                                                value={this.state.keyword}
                                                onChangeText={keyword => this.setState({keyword})}
                                                />
                                        </View>
                                    </View>
                                    <View style={{
                                        flexDirection: (this.state.originalWidth >= 767 ? 'row' : 'column'),
                                        justifyContent: 'flex-srart',
                                        alignItems: "flex-srart",
                                        padding: (this.state.originalWidth >= 767 ? 10 : 0),
                                        marginLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                        marginTop: (this.state.originalWidth >= 767 ? 0 : 10)
                                    }}>
                                        <Label font14Normal Barlow_Medium color={Color.BlackLight} style={{
                                            alignSelf: (this.state.originalWidth >= 767 ? "center" : "flex-start"),
                                            width: 110,
                                            textAlign: (this.state.originalWidth >= 767 ? "right" : "left"),
                                            marginRight: 20,
                                            marginBottom: (this.state.originalWidth >= 767 ? 0 : 8)
                                        }}>Download Limit</Label>
                                        <View style={{
                                            flexDirection: 'column',
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingTop: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingBottom: (this.state.originalWidth >= 767 ? 0 : 0),
                                            paddingLeft: (this.state.originalWidth >= 767 ? 0 : 10),
                                            paddingRight: (this.state.originalWidth >= 767 ? 0 : 10),
                                        }}>
                                            
                                            <View style={{width: 200}}>
                                            <Input
                                                style={{width: 200}}
                                                placeholder='min 1000'
                                                value={this.state.download_limit}
                                                onChangeText={download_limit => this.setState({download_limit})}
                                            />
                                            {this.state.isDownloadError === true && 
                                                <Text status={'danger'} category={'s2'}>Download limit should be greater than 1000</Text>
                                            }
                                            </View>
                                        </View>
                                    </View>
                                </Card> }
                                { this.state.showConfirmation === true && <Card 
                                header={<Text category='h5'>Request Submitted Successfully</Text>} 
                                style={{ marginTop: 40, width: '100%'}}
                                footer={<View><Button style={{width:100}} onPress={() => this.setState({showConfirmation: false, isReport: true})}>Go Back</Button></View>}>    
                                </Card> }   
                            </View>}  
                            { this.state.isSuperSet === true && <ScrollView>
                            <View style={{ alignContent:'center', alignItems:'center', justifyContent:'space-between'}}>
                            <Card 
                            header={<View style={{flexDirection:'row'}}>
                                <Text category='h6'> View for #{this.state.super_set_id}
                                <Button
                                style={{marginLeft: 30}}
                                status={'success'}
                                size={'tiny'} 
                                onPress={() => { this.resetReport(true); this.setState({isSuperSet: false})}}>Back</Button>
                                </Text>
                            </View>} 
                            style={{ marginTop: 40, width: 1200}}>
                                <ReportSuperSetView 
                                    authCode={this.state.authCode} 
                                    super_set_id={this.state.super_set_id} 
                                    {...this.props} 
                                    {...this.state} 
                                    org_id={this.state.organization_id} />
                            </Card>
                            </View> 
                        </ScrollView>}
                            </View>
                                            
        </View>
                </View>
            </View >
        )
    }
}

CampaignDataScreen.navigationOptions = {
    header: null,
};

export const ReportView = (props) => {

    const [show, setShow] = useState([]);
    const [data, setData] = useState([]);
    useEffect(() => {
        (async() => {
            try {
                const org_id = await AsyncStorage.getItem('organizationID');
                const userToken = await AsyncStorage.getItem('userToken');
                const data = await getReportData({org_id, userToken});
                setData(data);
                const arr = [];
                data.map((item, index) => arr.push({
                    [index] : false
                }));
                setShow(arr);
            } catch(e) {
            }
        })(); 
    }, []);
    
    const BulbIcon = (props) => (
        <Icon {...props} name={'bulb'} fill={'#005961'} style={{width: 20,
            height: 20}}  />
      );
      
    if (data.length === 0) { 
        return <View style={{alignItems:'center'}}><Text status='danger'>No Reports Created</Text></View>
    }

    if (data.length > 0) {
        return (
            <DataTable>
            <DataTable.Header>
                <DataTable.Title>#</DataTable.Title>
                <DataTable.Title>Job ID</DataTable.Title>
                <DataTable.Title>Search ID</DataTable.Title>
                <DataTable.Title>Status</DataTable.Title>
                <DataTable.Title style={{alignItems:'center'}}></DataTable.Title>
            </DataTable.Header>
            { data.map((item, index) => 
            <>
            <DataTable.Row>
                <DataTable.Cell>{index}</DataTable.Cell>
                <DataTable.Cell >{item.job_id}</DataTable.Cell>
                <DataTable.Cell >{item.search_id}</DataTable.Cell>
                <DataTable.Cell >{item.status}</DataTable.Cell>
                <DataTable.Cell >
                    <Button 
                        size='tiny'
                        style={{backgroundColor: Color.DarkGreen}} 
                        onPress={() => props.setSuperSetId(item.job_id)}> view </Button>
                </DataTable.Cell>
            </DataTable.Row>
            </>
            )}
            </DataTable>
        );
    }
    console.log('This page was crashing because this function was not returning anything')
    return (
        <View>
            
        </View>
    )
    
};

const ReportSuperSetView = (props) => {

    const [data, setData] = useState([]);
    const [message, setMessage] = useState();
    useEffect(() => {
        (async() => {
            try {
                const org_id = await AsyncStorage.getItem('organizationID');
                const userToken = await AsyncStorage.getItem('userToken');
                const data = await getInfoData({org_id, userToken, super_set_id:props.super_set_id});
                if(data.message) {
                    setMessage(data.message);
                } else {
                    setData(data);
                }
            } catch(e) {
            }
        })(); 
    }, []);
    
    const BulbIcon = (props) => (
        <Icon {...props} name={'bulb'} fill={'#005961'} style={{width: 20,
            height: 20}}  />
      );
      
    if (data.length === 0) { 
        return <View style={{alignItems:'center'}}><Text status='danger'>{message}</Text></View>
    }

    if (data.length > 0) {
        return (
            <DataTable>
            <DataTable.Header>
                <DataTable.Title>#</DataTable.Title>
                <DataTable.Title>Full Name</DataTable.Title>
                <DataTable.Title>Email</DataTable.Title>
                <DataTable.Title>Category</DataTable.Title>
                <DataTable.Title>Username</DataTable.Title>
                <DataTable.Title>Zip</DataTable.Title>
                <DataTable.Title style={{alignItems:'center'}}></DataTable.Title>
            </DataTable.Header>
            { data.map((item, index) => 
            <>
            <DataTable.Row>
                <DataTable.Cell>{index}</DataTable.Cell>
                <DataTable.Cell >{item.full_name}</DataTable.Cell>
                <DataTable.Cell >{item.public_email}</DataTable.Cell>
                <DataTable.Cell >{item.category}</DataTable.Cell>
                <DataTable.Cell >{item.username}</DataTable.Cell>
                <DataTable.Cell >{item.zip}</DataTable.Cell>
                <DataTable.Cell >
                    <Button 
                        size='tiny'
                        style={{backgroundColor: Color.DarkGreen}} 
                        onPress={() => props.setSuperSetId(item.job_id)}> view </Button>
                </DataTable.Cell>
            </DataTable.Row>
            </>
            )}
            </DataTable>
        );
    }

}

const base = StyleSheet.create({
    buttonContainer: {
            flex:1,
            minHeight:360,
            padding:10,
            paddingRight:40,
            alignSelf:'flex-start',
            alignContent:'flex-start',
    },
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
    },
    head: { height: 40, backgroundColor: '#808B97' },
  text: { margin: 6 },
  row: { flexDirection: 'row', backgroundColor: '#FFFFFF', padding: 5, margin:5 },
    leftBoxPart: {
        backgroundColor: 'background: rgba(255, 255, 255, 0.8)',
        paddingTop: 10,
        paddingBottom: 10,
    },
    boxColumn: {
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: Color.White,
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 12,
        paddingTop: 12,
        position: "relative"
    },
    submitBtn: {
        backgroundColor: Color.DarkGreen,
        width: 140,
        height: 44,
        borderRadius: 5,
        marginBottom: 0,
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    boxRow: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        //width: Dimensions.get("window").width - 150,
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 999999,
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },
    boxInteraction: {
    },


});
const styles = createStyles(
    base,
    maxWidth(767, {
        boxRow: {
            width: Dimensions.get("window").width - 30,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 30,
        },

        Container: {
            flex: 1,
            backgroundColor: Color.White,
        },
        containBox: {
            //width: Dimensions.get("window").width,
        }
    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        },
    })
);