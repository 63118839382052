import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  SafeAreaView,
  Text,
  Image, TouchableOpacity
} from 'react-native';
import { Color } from '../utils/color';
import PropTypes from 'prop-types';
import Label from '../components/Label';
import Button from './button';
import { createStyles, minWidth } from 'react-native-media-queries';

class SideMenu extends React.Component {
  componentDidMount() {
    //this.props.navigation.openDrawer();
    // this.props.navigation.closeDrawer();

  }
  render() {
    let { title, mainStyle } = this.props
    return (
      <SafeAreaView style={styles.Container}>
        <View style={styles.menuBox}>
        <TouchableOpacity onPress={() => {
            this.props.navigation.navigate("Dashboard", {'': ''})
          }}>
          <Image style={styles.welcomeImage} source={require("../assets/images/logo.png")} />
        </TouchableOpacity>
        </View>
        <View style={styles.SidebarMain}>
          <TouchableOpacity onPress={() => {
            this.props.navigation.navigate("Dashboard", {'':''})
          }} style={styles.menuList}>
            <Image style={{ height: 15, width: 15, position: 'absolute', tintColor: Color.White, }} source={require("../assets/images/dashboard.png")} />
            <Label font15Large Barlow_Medium color={Color.White} style={styles.menu}>Dashboard</Label>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => {
            this.props.navigation.navigate("Engage")
          }} style={styles.menuList}>
            <Image style={{ height: 15, width: 15, position: 'absolute', tintColor: Color.White }} source={require("../assets/images/engage.png")} />
            <Label font15Large Barlow_Medium color={Color.White} style={styles.menu} >Engage</Label>
          </TouchableOpacity>
          {/* <TouchableOpacity onPress={() => {
            this.props.navigation.navigate("OrganizationData")
          }} style={styles.menuList}>
            <Image style={{ height: 15, width: 15, position: 'absolute', tintColor: "white" }} source={require("../assets/images/Organization_Selected.png")} />
            <Label font15Large Barlow_Medium color={Color.White} style={styles.menu}>Organization Data</Label>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => {
            this.props.navigation.navigate("InviteUsers")
          }} style={styles.menuList}>
            <Image style={{ height: 15, width: 15, position: 'absolute', tintColor: "white" }} source={require("../assets/images/Organization_Selected.png")} />
            <Label font15Large Barlow_Medium color={Color.White} style={styles.menu}>Invite Users</Label>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => {
            this.props.navigation.navigate("InformationRequest")
          }} style={styles.menuList}>
            <Image style={{ height: 15, width: 15, position: 'absolute', tintColor: Color.White, }} source={require("../assets/images/dashboard.png")} />
            <Label font15Large Barlow_Medium color={Color.White} style={styles.menu}>Information Request</Label>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => {
            this.props.navigation.navigate("Notification")
          }} style={styles.menuList}>
            <Image style={{ height: 15, width: 15, position: 'absolute', tintColor: Color.White }} source={require("../assets/images/notification.png")} />
            <Label font15Large Barlow_Medium color={Color.White} style={styles.menu}>Customer Updates</Label>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => {
            this.props.navigation.navigate("Financial")
          }} style={styles.menuList}>
            <Image style={{ height: 15, width: 15, position: 'absolute', tintColor: Color.White }} source={require("../assets/images/financial.png")} />
            <Label font15Large Barlow_Medium color={Color.White} style={styles.menu} >Financial</Label>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => {
            this.props.navigation.navigate("LoginScreen")
          }} style={styles.menuList}>
            <Image style={{ height: 15, width: 15, position: 'absolute', tintColor: 'white' }} source={require("../assets/images/logout.png")} />
            <Label font15Large Barlow_Medium color={Color.White} style={styles.menu} >Logout</Label>
          </TouchableOpacity> */}
        </View>
      </SafeAreaView>
    );
  }
}

const base = StyleSheet.create({
  Container: {
    flex: 1,
  },
  SidebarMain: {
    width: 150,
    paddingBottom: 10,
    paddingTop: 10,
    backgroundColor: Color.DarkGreen,
    position: 'absolute',
    left: 0,
    height: '100%',
    top: 60
  },
  menuList: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 20,
    paddingBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
    color: Color.White,
    position: 'relative',
    borderBottomWidth: 1,
    borderBottomColor: Color.GreenLineColor
  },
  menu: {
    marginLeft: 30,
  },
  welcomeImage: {
    width: 40,
    height: 40,
    resizeMode: 'contain',
  },
  menuBox: {
    backgroundColor: Color.White,
    width: 150,
    height: 60,
    justifyContent: 'center',
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: "#005057"
  },
  menuBtn: {
    alignSelf: 'flex-end',
    marginTop: 10,
    marginRight: 30,
  },


});

const styles = createStyles(
  base,
  minWidth(768, {

  })
);
export default SideMenu;
