import AsyncStorage from '@react-native-async-storage/async-storage'
import { Constants } from '../utils/constants';

export const fetchProduct = async (start, limit, search_key, search_value) => {
  const organization_id = await AsyncStorage.getItem('organizationID');
  let response = await fetch(`${Constants.B2B_API}/organization/fetch-all-products?start=${start}&limit=${limit}&search_key=${search_key}&search_value=${search_value}&organization_id=${organization_id}`, {
    method: 'GET'
  });

  let responseJson = await response.json();

  let header = [
    'Location',
    'Distribution Network Type',
    'Email',
    'Phone',
    'Category',
    'Sub Category',
    'Name',
    'Size',
    'Color',
    'Price',
    'Make Date',
    'Expiry Date'
  ]

  let content = [];
  let { products } = responseJson
  for (let i = 0; i < products.length; i++) {
    let data = {};
    data.location = products[i].store_details.store_name;
    data.network = products[i].distribution_type;
    data.email = products[i].store_details.store_email;
    data.phone = products[i].store_details.store_contact;
    data.category = products[i].category;
    data.sub_category = products[i].sub_category;
    data.name = products[i].product_name;
    data.size = products[i].attribute_data.size;
    data.color = products[i].attribute_data.color;
    data.price = products[i].attribute_data.price;
    data.make_date = products[i].attribute_data.make_date;
    data.expiry_date = products[i].attribute_data.exp_date;
    data.store_id = products[i].store_details.store_id;
    data.cxs_product_id = products[i].cxs_product_id;
    data.organization_id = organization_id;
    content.push(data)
  }

  return {
    count: responseJson.count,
    limit: responseJson.limit,
    start: responseJson.start,
    header,
    content
  };

}

export const getProduct = async (organization_id, cxs_product_id) => {

  let response = await fetch(`${Constants.B2B_API}//organization/fetch-product?organization_id=${organization_id}&product_id=${cxs_product_id}`, {
    method: 'GET'
  });

  let responseJson = await response.json();

  let expection = [];
  let json = {};
  Object.keys(responseJson.products).map(item => {
    if (responseJson.products[item] instanceof Object === false) {
      json[item] = responseJson.products[item]
    }
  })

  Object.keys(responseJson.products.attribute_data).map(item => {
    if (responseJson.products[item] instanceof Object === false) {
      json[item] = responseJson.products.attribute_data[item]
    }
  })

  Object.keys(responseJson.products.store_details).map(item => {
    if (responseJson.products[item] instanceof Object === false) {
      json[item] = responseJson.products.store_details[item]
    }
  })

  return json;
}

export const getCategories = async () => {

  let response = await fetch(`${Constants.B2B_API}//organization/fetch-all-categories`, {
    method: 'GET'
  });

  let responseJson = await response.json();
  return responseJson.categories;
}

export const getSubCategories = async (category_id) => {

  let response = await fetch(`${Constants.B2B_API}//organization/fetch-sub-categories?category_id=${catgory_id}`, {
    method: 'GET'
  });

  let responseJson = await response.json();
  return responseJson;
}

export const updateProduct = async (data) => {

  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  };

  let response = await fetch(`${Constants.B2B_API}/organization/update-product`, requestOptions)
  return response
}

