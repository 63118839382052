import { Constants } from "../utils/constants";

export const postData = async (params) => {
  let queryString = `query=${params.keyword}&`;
  queryString += `country=${params.country}&`;
  queryString += `lang=${params.language}&`;
  queryString += `resolution=${params.resolution}&`;
  queryString += `geo_state=${params.geo_state}&`;
  queryString += `geo_country=${params.geo_country}`

  return await fetch(
    `${Constants.BASE_URL}/data-request`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + params.userToken
    },
    body: JSON.stringify({
      keywords: params.keyword && params.keyword.split(','),
      download_limit: parseInt(params.download_limit),
      country: params.country,
      language: params.language,
      search_id: params.search_id
    })
  });
}

export const getReportData = async (options) => {
  const userToken = options.userToken;
  delete options.userToken;
  let response = await fetch(`${Constants.BASE_URL}/data-request/status`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + userToken
    }
  });
  let responseJson = await response.json();
  return responseJson;
}

export const getInfoData = async (options) => {
  const userToken = options.userToken;
  delete options.userToken;
  let response = await fetch(`${Constants.BASE_URL}/data-request/${options.super_set_id}/sample`, {
    method: 'GET',

    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + userToken
    }
  });
  let responseJson = await response.json();
  return responseJson;
}
