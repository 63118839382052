import React, { Component } from 'react'
import { ScrollView, StyleSheet, FlatList, View,
    Image, Dimensions, TouchableOpacity, Platform } from 'react-native'
import { Select, SelectItem, Text, Input, List, ListItem, Divider,IndexPath, Button } from '@ui-kitten/components';
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Color } from '../utils/color';
import MobileHeader from '../components/MobileHeader';
import MenuSettings from '../components/MenuSettings';
import SideMenu from '../components/SideMenu';
import LeadCxResultComponents from '../components/LeadCxResultComponents';
import Label from '../components/Label';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import countryJson from '../data/country.json';
import languageJson from '../data/language.json';
import { Entypo } from '@expo/vector-icons';
import { Analytics } from '../components/Analytics';
var moment = require('moment');

const normalList = ['Social', 'Direct'];
const customerList = ['Social', 'Direct', 'Own Site', 'Offline'];
const socialList = ['Instagram','Google','LinkedIn','Facebook', 'Others'];
const directList = ['Email','SMS', 'WhatsApp'];
const buyList = ['Rewards', 'Money', 'Brand', 'Quality', 'Others'];
const referList = ['Yes', 'No'];

export default class LeadcxScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            isSubmitted: false,
            openFile : false,
            organization_id: null,
            myWidth: Dimensions.get("window").width - 730,
            originalWidth: Dimensions.get("window").width,
            fullName:null,
            competition:null,
            socialValues:[],
            directValues:[],
            buyValues:[],
            channelValues:[],
            normalSocial:[],
            normalDirect:[],
            buy:[],
            channel:[],
            leadcx: {
                from: null,
                keyword: null,
                country: null,
                language: null,
                normal: null,
                normalSocial: [],
                normalDirect:[],
                buy: [],
                purchase:null,
                attribution: null,
                refer:null,
                channel:[],
                competitionList: [],
                customer:{
                    list: []
                }
            }
        }
    }
    async componentWillMount() {

        const fullName = await AsyncStorage.getItem('fullName');
        const organization_id = await AsyncStorage.getItem('organizationID');
        this.setState({
            fullName,
            organization_id
        })

    }

    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })

        if (width >= 767) {

            this.setState({
                navWidth:
                    Platform.select({
                        web: width - 150,
                        default: Dimensions.get("window").width,
                    })
            })

        } else {
            this.setState({
                navWidth:
                    Platform.select({
                        web: width,
                        default: Dimensions.get("window").width,
                    })
            })
        }

        // MY Width :::


        if (width > 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }

        this.setState(() => ({ isWide: width >= 767 }));
    };

    openSystem = () => {
        this.setState({
            openFile:true
        })
    }

    handleFromChange = (e) => {
        this.state.leadcx.from = e.target.value;
        this.setState({leadcx: this.state.leadcx});
    };

    handleKeywordChange = (e) => {
        this.state.leadcx.keyword = e.target.value;
        this.setState({leadcx: this.state.leadcx});
    };

    handlePurchaseChange = (e) => {
        this.state.leadcx.purchase = e.target.value;
        this.setState({leadcx: this.state.leadcx});
    };

    handleAttributionChange = (e) => {
        this.state.leadcx.attribution = e.target.value;
        this.setState({leadcx: this.state.leadcx});
    };

    handleCompetitionChange = (e) => {
        this.state.competition = e.target.value;
    };

    handleCustomerDirectChange = (e) => {
        this.state.leadcx.customer.direct = e.target.value;
    };

    handleCustomerSocialChange = (e) => {
        this.state.leadcx.customer.socialUrl = e.target.value;
    };

    setSelectedCountryIndex = (index) => {
        this.state.leadcx.country = countryJson[index - 1];
        this.setState({leadcx: this.state.leadcx});
    }

    setSelectedLanguageIndex = (index) => {
        this.state.leadcx.language = languageJson[index - 1].name;
        this.setState({leadcx: this.state.leadcx});
    }

    setSelectedNormalIndex = (index) => {
        this.state.leadcx.normal = normalList[index - 1];
        this.setState({leadcx: this.state.leadcx});
    }

    setSelectedNormalDirectIndex = (iArray) => {
        let arr = [];
        let view = [];
        let value = [];
        for(let i=0;i<iArray.length;i++) {
            const item = directList[iArray[i].row];
            arr.push(item);
            value.push(item);
            view.push(new IndexPath(iArray[i].row));
        }
        const {leadcx} = this.state;
        leadcx.normalDirect = arr;
        this.setState({ normalDirect: view });
        this.setState({leadcx});
        this.setState({ directValues: value});
    }

    setSelectedNormalSocialIndex = (iArray) => {
        let arr = [];
        let view = [];
        let values = [];
        for(let i=0;i<iArray.length;i++) {
            const item = socialList[iArray[i].row];
            arr.push(item);
            view.push(new IndexPath(iArray[i].row));
            values.push(item);
        }
        const {leadcx} = this.state;
        leadcx.normalSocial = arr;
        this.setState({ normalSocial: view });
        this.setState({leadcx});
        this.setState({socialValues: values});
    }

    setSelectedBuyIndex = (iArray) => {
        let arr = [];
        let view = [];
        let value = [];
        for(let i=0;i<iArray.length;i++) {
            const item = buyList[iArray[i].row];
            arr.push(item);
            value.push(item);
            view.push(new IndexPath(iArray[i].row));
        }
        const {leadcx} = this.state;
        leadcx.buy = arr;
        this.setState({ buy: view });
        this.setState({leadcx});
        this.setState({buyValues:value});
    }

    setSelectedReferIndex = (index) => {
        this.state.leadcx.refer = referList[index - 1];
        this.setState({leadcx: this.state.leadcx});
    }
    
    setSelectedChannelIndex = (iArray) => {
        let arr = [];
        let view = [];
        let value = [];
        for(let i=0;i<iArray.length;i++) {
            const item = directList[iArray[i].row];
            arr.push(item);
            value.push(item);
            view.push(new IndexPath(iArray[i].row));
        }
        const {leadcx} = this.state;
        leadcx.channel = arr;
        this.setState({ channel: view });
        this.setState({leadcx});    
        this.setState({channelValues:value});
    }

    setSelectedCustomerIndex =  (index) => {
        this.state.leadcx.customer.name = customerList[index - 1];
        this.setState({leadcx: this.state.leadcx});
    }

    setSelectedCustomerSocialIndex = (index) => {
        this.state.leadcx.customer.social = socialList[index - 1];
        this.setState({leadcx: this.state.leadcx});
    }

    handleCompetitionClick = () => {
        if(this.state.competition) {    
            let list = this.state.leadcx.competitionList;
            list.push(this.state.competition);
            this.state.leadcx.competitionList = list;
            this.setState({leadcx: this.state.leadcx});
        }
    };
    
    handleCustomerClick = () => {
        const list = [...this.state.leadcx.customer.list];
        const object = {};
        object.type = this.state.leadcx.customer.name;
        if(this.state.leadcx.customer.name === 'Direct') {
          object['value'] = this.state.leadcx.customer.direct;
        }
        if(this.state.leadcx.customer.name === 'Social') {
            object['value'] = this.state.leadcx.customer.social;
        }
        if(this.state.leadcx.customer.socialUrl) {
            object['url'] = this.state.leadcx.customer.socialUrl;
        }
        list.push(object);
        this.state.leadcx.customer.list = list;
        this.setState(this.state.leadcx);
    }
  
    renderCompetitionItemAccessory = (index) => (
        <Entypo name="trash" size={24} color="black" onPress={() => this.handleCompetitionRemove(index)}/>
    )

    renderCustomerItemAccessory = (index) => (
        <Entypo name="trash" size={24} color="black" onPress={() => this.handleCustomerRemove(index)}/>
    )

    renderCompetitionItem = ({ item, index }) => (
        <ListItem
          title={`${item}`}
          accessoryRight={() => this.renderCompetitionItemAccessory(index)}
        />
    );
    
    renderCustomerItem = ({ item, index }) => (
        <ListItem
          title={`${item.type} - ${item.value || ''} - ${item.url}`}
          accessoryRight={() => this.renderCustomerItemAccessory(index)}
        />
    );
    
    handleCompetitionRemove = (index) => {
        const {leadcx} = this.state;
        let list = [...leadcx.competitionList];
        list.splice(index,1);
        leadcx.competitionList = list;
        this.setState(leadcx);
    }

    handleCustomerRemove = (index) => {
        const {leadcx} = this.state;
        let list = [...leadcx.customer.list];
        list.splice(index,1);
        leadcx.customer.list = list;
        this.setState(leadcx);
    }

    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }

        const {leadcx} = this.state;
        return (
            <View onLayout={this._handleLayout} style={styles.Container}>
                <Analytics />
                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Leadcx" />}
                    <View style={{ flexDirection: 'row', flex: 1, }}>
                    {   this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}
                    
                    <View style={[styles.containBox, { width: this.state.myWidth }]}>
                        {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                            width: this.state.myWidth,
                            justifyContent: "center",
                            alignItems: 'center'
                        }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'),
                                textAlign: 'center',
                                alignSelf: 'center',
                            }]}>Leadcx</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../assets/images/small_down.png")} />
                                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("./../../assets/images/profile_img.png")} /> */}
                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>
                            </TouchableOpacity>
                        </View>}
                        <MenuSettings 
                            {...this.props} 
                            isOpen={this.state.isOpen} 
                            myWidth={this.state.myWidth} 
                            onClick={() =>this.setState({ isOpen: false})} 
                        />
                            { this.state.isSubmitted === false && <ScrollView> 
                                <View style={{padding:20}}>
                                <View style={{width:'60%', padding:10}}>
                                    <Label style={{marginBottom:10}}>who is your ideal customer ?</Label>
                                    <Input onChange={this.handleFromChange} placeholder={'Ideal Customer'} />
                                </View>
                                <View style={{width:'60%', padding:10}}>
                                    <Label style={{marginBottom:10}}>What are you top 2 search criteria ?</Label>
                                    <View style={{flexDirection:'row'}}>
                                        <Input onChange={this.handleKeywordChange} placeholder={'Keyword'} />
                                        <Select style={{marginLeft:10}}
                                            value={this.state.leadcx.country}
                                            placeholder={'Select Country'}
                                            onSelect={index => this.setSelectedCountryIndex(index)}>
                                            {countryJson.map((item) => <SelectItem title={item} />)}        
                                        </Select>
                                        <Select style={{marginLeft:10}}
                                            value={this.state.leadcx.language}
                                            placeholder={'Select Language'}
                                            onSelect={index => this.setSelectedLanguageIndex(index)}>
                                            {languageJson.map((item) => <SelectItem title={item.name} />)}        
                                        </Select>
                                    </View>    
                                </View>
                                <View style={{width:'60%', padding:10}}>
                                    <Label style={{marginBottom:10}}>What is your normal target site / mechanism ?</Label>
                                    <View style={{flexDirection:'row'}}>
                                        <Select
                                            value={this.state.leadcx.normal}
                                            placeholder={'Select Option'}
                                            onSelect={index => this.setSelectedNormalIndex(index)}>
                                            {normalList.map((item) => <SelectItem title={item} />)}        
                                        </Select>
                                        {leadcx.normal === 'Social' && <Select style={{marginLeft:10}}
                                            value={this.state.socialValues.join(', ')}
                                            selectedIndex={this.state.normalSocial}
                                            multiSelect={true}
                                            placeholder={'Select Social Option'}
                                            onSelect={index => this.setSelectedNormalSocialIndex(index)}>
                                            {socialList.map((item) => <SelectItem title={item} />)}        
                                        </Select> }
                                        {leadcx.normal === 'Direct' && <Select style={{marginLeft:10}}
                                            value={this.state.directValues.join(', ')}
                                            selectedIndex={this.state.normalDirect}
                                            multiSelect={true}
                                            placeholder={'Select Direct Option'}
                                            onSelect={index => this.setSelectedNormalDirectIndex(index)}>
                                            {directList.map((item) => <SelectItem title={item} />)}        
                                        </Select>}
                                    </View> 
                                </View>    
                                <View style={{width:'60%', padding:10 }}>
                                        <Label style={{marginBottom:10}}>Why will customer buy from you?</Label>
                                        <Select style={{width:'30%'}}
                                            value={this.state.buyValues.join(', ')}
                                            selectedIndex={this.state.buy}
                                            multiSelect={true}
                                            placeholder={'Select Buy Option'}
                                            onSelect={index => this.setSelectedBuyIndex(index)}>
                                            {buyList.map((item) => <SelectItem title={item} />)}        
                                        </Select>
                                </View>
                                <View style={{width:'60%', padding:10}}>
                                    <Label style={{marginBottom:10}}>What is your Purchase/ Subscription URL ?</Label>
                                    <Input onChange={this.handlePurchaseChange} placeholder={'URL'} />
                                </View>
                                <View style={{width:'60%', padding:10}}>
                                    <Label style={{marginBottom:10}}>What is your attribution URL (if separate from subscription URL) ?</Label>
                                    <Input onChange={this.handleAttributionChange} placeholder={'URL'} />
                                </View>
                                <View style={{width:'60%', padding:10 }}>
                                        <Label style={{marginBottom:10}}>Do you want your customers to refer other customers ?</Label>
                                        <Select style={{width:'30%'}}
                                            value={this.state.leadcx.refer}
                                            placeholder={'Select Refer Option'}
                                            onSelect={index => this.setSelectedReferIndex(index)}>
                                            {referList.map((item) => <SelectItem title={item} />)}        
                                        </Select>
                                </View>
                                <View style={{width:'60%', padding:10 }}>
                                        <Label style={{marginBottom:10}}>Channel ?</Label>
                                        <Select style={{width:'30%'}}
                                            value={this.state.channelValues.join(', ')}
                                            selectedIndex={this.state.channel}
                                            multiSelect={true}
                                            placeholder={'Select Channel Option'}
                                            onSelect={index => this.setSelectedChannelIndex(index)}>
                                            {directList.map((item) => <SelectItem title={item} />)}        
                                        </Select>
                                </View>
                                <View style={{width:'60%', padding:10}}>
                                    <Label style={{marginBottom:10}}>Who are your competition ? Please suggest URL</Label>
                                    <View style={{flexDirection:'row'}}>
                                        <Input onChange={this.handleCompetitionChange} placeholder={'URL'} />
                                        <Entypo name="add-to-list" size={24} color="black" style={{padding:10}} onPress={this.handleCompetitionClick}/>
                                    </View> 
                                    <View style={{ width:'60%'}}>
                                        <List
                                            data={leadcx.competitionList}
                                            ItemSeparatorComponent={Divider}
                                            renderItem={this.renderCompetitionItem}
                                        />
                                    </View>  
                                </View>
                                <View style={{width:'60%', padding:10}}>
                                    <Label style={{marginBottom:10}}>Where do they get customers ?</Label>
                                    <View style={{flexDirection:'row', width:'100%'}}>
                                        <Select
                                            value={this.state.leadcx.customer.name}
                                            placeholder={'Select Customer Option'}
                                            onSelect={index => this.setSelectedCustomerIndex(index)}>
                                            {customerList.map((item) => <SelectItem title={item} />)}        
                                        </Select>
                                        {leadcx.customer.name === 'Social' && <Select style={{marginLeft:10}}
                                            value={this.state.leadcx.customer.social}
                                            placeholder={'Select Social Option'}
                                            onSelect={index => this.setSelectedCustomerSocialIndex(index)}>
                                            {socialList.map((item) => <SelectItem title={item} />)}        
                                        </Select> }
                                        {leadcx.customer.name === 'Direct' && <Input onChange={this.handleCustomerDirectChange} placeholder={'Direct'} />}
                                        {leadcx.customer.social && <Input onChange={this.handleCustomerSocialChange} placeholder={'URL'} />}
                                        { leadcx.customer.name && <Entypo name="add-to-list" size={24} color="black" style={{padding:10}} onPress={this.handleCustomerClick}/> }  
                                    </View>
                                    <View style={{ width:'60%'}}>
                                        <List
                                            data={leadcx.customer.list}
                                            ItemSeparatorComponent={Divider}
                                            renderItem={this.renderCustomerItem}
                                        />
                                    </View>
                                    <View style={{marginTop:35, width: 150, marginBottom:20}}>
                                        <Text> Work in Progress </Text>
                                    </View> 
                                    {/* 
                                    <View style={{marginTop:15, width: 150}}>
                                        <Button onPress={() => this.setState({isSubmitted : true})}>Submit</Button>
                                    </View> */}
                                </View>    
                                </View>
                            </ScrollView> }
                            {this.state.isSubmitted === true && <ScrollView>
                                <LeadCxResultComponents {...this.props }/>
                            </ScrollView>}  
                    </View>
                </View>
            </View >
        )
    }
}

LeadcxScreen.navigationOptions = {
    header: null,
};
const base = StyleSheet.create({
    buttonContainer: {
            flex:1,
            minHeight:360,
            padding:10,
            paddingRight:40,
            alignSelf:'flex-start',
            alignContent:'flex-start',
    },
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
    },
    head: { height: 40, backgroundColor: '#808B97' },
  text: { margin: 6 },
  row: { flexDirection: 'row', backgroundColor: '#FFFFFF', padding: 5, margin:5 },
    leftBoxPart: {
        backgroundColor: 'background: rgba(255, 255, 255, 0.8)',
        paddingTop: 10,
        paddingBottom: 10,
    },
    boxColumn: {
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: Color.White,
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 12,
        paddingTop: 12,
        position: "relative"
    },
    submitBtn: {
        backgroundColor: Color.DarkGreen,
        width: 140,
        height: 44,
        borderRadius: 5,
        marginBottom: 0,
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    boxRow: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        //width: Dimensions.get("window").width - 150,
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 999999,
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },
    boxInteraction: {
    },


});
const styles = createStyles(
    base,
    maxWidth(767, {
        boxRow: {
            width: Dimensions.get("window").width - 30,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 30,
        },

        Container: {
            flex: 1,
            backgroundColor: Color.White,
        },
        containBox: {
            //width: Dimensions.get("window").width,
        }
    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        },
    })
);
