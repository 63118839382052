import React, { Component } from 'react'
import { SafeAreaView, StyleSheet, FlatList, Text, View, Image, Dimensions, TouchableOpacity, Platform, Button } from 'react-native'
import { Color } from './../../utils/color';
import { Constants } from './../../utils/constants';
import MobileHeader from './../../components/MobileHeader';
import SideMenu from './../../components/SideMenu';
import MenuSettings from '../../components/MenuSettings';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import Input from '../../components/Input.web';
import Label from './../../components/Label';
import AsyncStorage from '@react-native-async-storage/async-storage'
import { ScrollView } from 'react-native-gesture-handler';
import DateTimePicker from "react-native-modal-datetime-picker";
var moment = require('moment');
import { Analytics } from '../../components/Analytics';
export default class Financial extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            myWidth: Dimensions.get("window").width - 150,
            isOpen: false,
            originalWidth: Dimensions.get("window").width,
            fullName: "",
            arrBilling: [],
            arrInfoRequest: [],
            filterData: [],
            isDateTimePickerVisible: false,
            isDateTimePickerVisibleEnd: false,
            startDate: "",
            endDate: "",
            isSubmit: true
        }
    }
    async componentWillMount() {

        const userToken = await AsyncStorage.getItem('userToken');
        if (userToken == "null") {
            this.props.navigation.navigate('LoginScreen');
        } else {
            this.financeAPI()
        }

        const fullName = await AsyncStorage.getItem('fullName');
        this.setState({
            fullName: fullName
        })

    }
    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })

        if (width >= 767) {

            this.setState({
                navWidth:
                    Platform.select({
                        web: width - 150,
                        default: Dimensions.get("window").width,
                    })
            })

        } else {
            this.setState({
                navWidth:
                    Platform.select({
                        web: width,
                        default: Dimensions.get("window").width,
                    })
            })
        }

        // MY Width :::


        if (width > 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }

        this.setState(() => ({ isWide: width >= 767 }));
    };

    // API :::

    financeAPI = async () => {

        const userToken = await AsyncStorage.getItem('userToken');
        const organizationID = await AsyncStorage.getItem('organizationID');

        try {

            let url = `${Constants.BASE_URL}${Constants.ORGANIZATION}/${organizationID}${Constants.FINANCIAL}`

            let response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                }
            });
            let responseJson = await response.json();

            // console.log("=== responseJson ===", responseJson);

            if (response.status == 200) {

                this.setState({
                    arrInfoRequest: responseJson.informationRequestFinancials,
                    filterData: responseJson.informationRequestFinancials.filter((item, index) => index <= 10),
                    arrBilling: responseJson.subscriptionFinancials.filter((item, index) => index <= 5)
                })

            } else {
                if (Platform.OS === 'web') {
                    alert(responseJson.message)
                } else {
                    Alert.alert("CXSphere", responseJson.message)
                }
            }
        } catch (error) {
            console.error("====== error =====", error);
            // if (Platform.OS === 'web') {
            //     alert(responseJson.message)
            // } else {
            //     Alert.alert("CXSphere", responseJson.message)
            // }
        }

    }

    showDateTimePicker = () => {
        this.setState({ isDateTimePickerVisible: true });
    };

    showDateTimePickerEnd = () => {
        this.setState({ isDateTimePickerVisibleEnd: true });
    };

    hideDateTimePicker = () => {
        this.setState({ isDateTimePickerVisible: false, isDateTimePickerVisibleEnd: false });
    };

    handleDatePickedStart = date => {
        // console.log("A date has been picked Start: ", moment(date).format('YYYY-MM-DD'));
        this.setState({
            startDate: moment(date).format('YYYY-MM-DD').toString()
        })
        this.hideDateTimePicker();
    };


    handleDatePickedEnd = date => {
        // console.log("A date has been picked End: ", moment(date).format('YYYY-MM-DD'));
        this.setState({
            endDate: moment(date).format('YYYY-MM-DD').toString()
        })
        this.hideDateTimePicker();
    };

    onSubmitFilter = () => {

        if (this.state.isSubmit) {
            var dates = this.state.arrInfoRequest.filter((item) => {
                return new Date(item.paymentDate).getTime() >= new Date(this.state.startDate).getTime() && new Date(item.paymentDate).getTime() <= new Date(this.state.endDate).getTime()
            }
            );

            if (this.state.startDate.length > 0 && this.state.endDate.length > 0) {
                this.setState({
                    filterData: dates,
                    isSubmit: false,
                })
            }

        } else {
            this.setState({
                filterData: this.state.arrInfoRequest.filter((item, index) => index <= 10),
                isSubmit: true,
                startDate: "",
                endDate: ""
            }, () => {
                this.forceUpdate()
            })
        }
    }



    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }

        return (
            <View onLayout={this._handleLayout} style={styles.Container}>
                <Analytics />
                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Financial" />}
                <View style={{ flexDirection: 'row', flex: 1, }}>
                    {this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}

                    <View style={[styles.containBox, { width: this.state.myWidth }]}>
                        {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                            width: this.state.myWidth,
                            justifyContent: "center",
                            alignItems: 'center'
                        }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'),
                                textAlign: 'center',
                                alignSelf: 'center',
                            }]}>Financial</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../../assets/images/small_down.png")} />
                                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("./../../assets/images/profile_img.png")} /> */}
                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>
                            </TouchableOpacity>
                        </View>}
                        <MenuSettings 
                            {...this.props} 
                            isOpen={this.state.isOpen} 
                            myWidth={this.state.myWidth} 
                            onClick={() =>this.setState({ isOpen: false})} 
                        />
                        <View style={[styles.leftBoxPart, {
                            width: (this.state.myWidth > 767 ? 700 : this.state.myWidth),
                            alignSelf: "center",
                            padding: 10,
                            backgroundColor: "white",
                            marginTop: this.state.myWidth > 767 ? 20 : 0,
                            marginBottom: this.state.myWidth > 767 ? 20 : 0,
                            height: (this.state.myWidth > 767 ? Dimensions.get("window").height - 140 : '100%'),
                        }]}>

                            <ScrollView >
                                <Label
                                    style={{ width: (this.state.myWidth > 767 ? 700 : this.state.myWidth) }}
                                    font18Large
                                    Barlow_Medium>CXSphere Billing</Label>

                                {/* Header */}

                                <View style={{
                                    flexDirection: "row",
                                    height: 40,
                                    marginTop: 10,
                                    alignItems: "center",
                                    width: (this.state.myWidth > 767 ? 700 : this.state.myWidth)
                                }}>

                                    <Label font18Large Barlow_Medium color={Color.BlackLight} style={{
                                        width: "30%",
                                    }}>Month</Label>

                                    <Label font18Large Barlow_Medium color={Color.BlackLight} style={{
                                        width: "30%",
                                        textAlign: "center"
                                    }}>Amount</Label>

                                    <Label font18Large Barlow_Medium color={Color.BlackLight} style={{
                                        width: "40%",
                                        textAlign: "center"
                                    }}>Revenue</Label>

                                </View>

                                {/* Listing */}

                                {this.state.arrBilling.map(item => {
                                    return (
                                        <View style={{
                                            flexDirection: "row",
                                            height: 30,
                                            alignItems: "center",
                                            width: (this.state.myWidth > 767 ? 700 : this.state.myWidth)
                                        }}>
                                            <Label style={{ width: "30%", }} Barlow_SemiBold font14Normal color={Color.DarkBlackGrey}>{item.paymentDate}</Label>
                                            <Label style={{
                                                width: "30%", textAlign: "center"
                                            }} Barlow_SemiBold font14Normal color={Color.DarkBlackGrey}>{item.amount}</Label>
                                            <Label style={{
                                                width: "40%", textAlign: "center"
                                            }} Barlow_SemiBold font14Normal color={Color.DarkBlackGrey}>{'Not available'}</Label>

                                        </View>
                                    )
                                })}


                                {/* SEPERATOR */}

                                <View style={{
                                    width: (this.state.myWidth > 767 ? 660 : this.state.myWidth),
                                    backgroundColor: Color.BORDERCOLOR,
                                    height: 1,
                                    marginTop: 5,
                                    marginBottom: 5
                                }} />

                                {/* Info Request Page */}

                                <Label mt={5} style={{ width: (this.state.myWidth > 767 ? 700 : this.state.myWidth - 40) }} font18Large Barlow_Medium>Information Request Payment</Label>


                                {/* Input Text */}

                                <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: "center" }}>
                                    <View style={{ flexDirection: 'row', height: 60, alignItems: 'center', marginRight: 10 }}>
                                        <Label
                                            font18Large
                                            Barlow_Medium
                                            color={Color.BlackLight}
                                            style={{ width: 120 }}>Start Date: </Label>

                                        {Platform.OS == 'web' && <Input value={this.state.startDate} inputProps={{ readOnly: true }} handleDayChange={(date) => this.handleDatePickedStart(date)} />}

                                        {Platform.OS != 'web' && <TouchableOpacity onPress={this.showDateTimePicker} style={{
                                            borderWidth: 1,
                                            borderColor: Color.DarkGreen,
                                            borderRadius: 5,
                                            backgroundColor: Color.White,
                                            width: 140,
                                            height: 40,
                                            alignItems: "center",
                                            justifyContent: 'center'
                                        }}>

                                            <Label
                                                font18Large
                                                Barlow_Medium
                                                color={Color.BlackLight}
                                                style={{ width: 120 }}>{this.state.startDate}</Label>
                                        </TouchableOpacity>}


                                        {Platform.OS != 'web' && <DateTimePicker
                                            isVisible={this.state.isDateTimePickerVisible}
                                            onConfirm={this.handleDatePickedStart}
                                            onCancel={this.hideDateTimePicker}
                                        />}



                                    </View>

                                    <View style={{ flexDirection: 'row', height: 60, alignItems: 'center', marginRight: 10, zIndex: -1 }}>
                                        <Label
                                            font18Large
                                            Barlow_Medium
                                            color={Color.BlackLight}
                                            style={{ width: 120 }}>End Date: </Label>



                                        {Platform.OS == 'web' && <Input value={this.state.endDate} inputProps={{ readOnly: true }} handleDayChange={(date) => this.handleDatePickedEnd(date)} />}


                                        {Platform.OS != 'web' && <TouchableOpacity onPress={this.showDateTimePickerEnd} style={{
                                            borderWidth: 1,
                                            borderColor: Color.DarkGreen,
                                            borderRadius: 5,
                                            backgroundColor: Color.White,
                                            width: 140,
                                            height: 40,
                                            alignItems: "center",
                                            justifyContent: 'center'
                                        }}>

                                            <Label
                                                font18Large
                                                Barlow_Medium
                                                color={Color.BlackLight}
                                                style={{ width: 120 }}>{this.state.endDate}</Label>
                                        </TouchableOpacity>}


                                        {Platform.OS != 'web' && <DateTimePicker
                                            isVisible={this.state.isDateTimePickerVisibleEnd}
                                            onConfirm={this.handleDatePickedEnd}
                                            onCancel={this.hideDateTimePicker}
                                        />}

                                    </View>

                                    <TouchableOpacity onPress={() => {
                                        this.onSubmitFilter()
                                    }} style={[styles.submitBtn, {
                                        justifyContent: 'center',
                                        alignItems: "center",
                                        zIndex: -2
                                    }]}>
                                        <Label Barlow_SemiBold font14Normal color={Color.White}>{this.state.isSubmit ? "Submit" : "Clear"}</Label>
                                    </TouchableOpacity>

                                </View>

                                {/* INFO LIST*/}

                                <View style={{
                                    flexDirection: "row",
                                    height: 40,
                                    marginTop: 10,
                                    alignItems: "center",
                                    width: (this.state.myWidth > 767 ? 700 : this.state.myWidth),
                                    zIndex: -1
                                }}>

                                    <Label font18Large Barlow_Medium color={Color.BlackLight} style={{
                                        width: "30%",
                                    }}>Payment Date</Label>

                                    <Label font18Large Barlow_Medium color={Color.BlackLight} style={{
                                        width: "30%",
                                        textAlign: "center"
                                    }}>Payment Amount</Label>

                                    <Label style={{ width: "30%" }} font15Large Barlow_Bold color={Color.DarkGreen} >{""}</Label>
                                </View>

                                {this.state.filterData.map(item => {
                                    return (
                                        <View style={{
                                            flexDirection: "row",
                                            height: 30,
                                            alignItems: "center",
                                            width: (this.state.myWidth > 767 ? 700 : this.state.myWidth),
                                            zIndex: -1
                                        }}>

                                            <Label style={{ width: "30%", }} Barlow_SemiBold font14Normal color={Color.DarkBlackGrey}>{item.paymentDate}</Label>
                                            <Label style={{
                                                width: "30%", textAlign: "center"
                                            }} Barlow_SemiBold font14Normal color={Color.DarkBlackGrey}>{item.amount}</Label>
                                            <TouchableOpacity style={{ width: "40%", height: 20, backgroundColor: Color.TRANSPARENT, alignItems: 'center', justifyContent: "center" }}>
                                                <Label Barlow_SemiBold font14Normal color={Color.DarkGreen} >{"Details"}</Label>
                                            </TouchableOpacity>

                                        </View>

                                    )
                                })}


                            </ScrollView>

                        </View>

                    </View>
                </View>
            </View >
        )
    }
}

Financial.navigationOptions = {
    header: null,
};
const base = StyleSheet.create({
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
    },
    leftBoxPart: {
        backgroundColor: 'background: rgba(255, 255, 255, 0.8)',
        paddingTop: 10,
        paddingBottom: 10,
    },
    boxColumn: {
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: Color.White,
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 12,
        paddingTop: 12,
        position: "relative"
    },
    submitBtn: {
        backgroundColor: Color.DarkGreen,
        width: 140,
        height: 44,
        borderRadius: 5,
        marginBottom: 0,
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    boxRow: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        //width: Dimensions.get("window").width - 150,
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 999999,
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },
    boxInteraction: {
    },


});
const styles = createStyles(
    base,
    maxWidth(767, {
        boxRow: {
            width: Dimensions.get("window").width - 30,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 30,
        },

        Container: {
            flex: 1,
            backgroundColor: Color.White,
        },
        containBox: {
            //width: Dimensions.get("window").width,
        }
    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        },
    })
);





