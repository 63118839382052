export const getCitiesJson = async () => {
    try {

        let url = 'https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/cities.json'

        let response = await fetch(url, {
            method: 'GET',
        });
        let responseJson = await response.json();
        if (responseJson) {
            return responseJson
        } 
    } catch (error) {
        console.error("====== error =====");
    }
}