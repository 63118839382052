import React, { useState, useCallback, useContext, useEffect } from "react";
import { View, Image, Dimensions, Button, Text, TouchableOpacity, StyleSheet } from 'react-native'
import * as DocumentPicker from 'expo-document-picker';
import { Color } from './../utils/color';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Constants } from './../utils/constants';
import { MaterialCommunityIcons } from '@expo/vector-icons';


const Upload = ({ type }) => {
  
    const [success, setSuccess] = useState('')
    const [file, setFile] = useState()
    const [fileName, setFileName] = useState('')
    const [fileSize, setFileSize] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const getFile = async()=>{
      setSuccess('')
      setErrorMessage('')
      let result = await DocumentPicker.getDocumentAsync({
        type: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/csv']
        })

      if(result.file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || result.file.type == 'text/csv'){
        setFile(()=>result)
      } else {
        setErrorMessage('Error file type not supported.')
        return
      }
    }

    const removeFile = async()=>{
      setSuccess('')
      setFile()
      setFileName('')
      setFileSize('')
      setErrorMessage('')
    }

    useEffect(()=>{
     if(file){
      setFileName(file.name)
      setFileSize(file.size)
     } return
    },[file])

    const _pickDocument = async () => {
      //product uploader
        let result = file
        const data = new FormData();
        let organization_id =  await AsyncStorage.getItem('organizationID');
        data.append('files', result.file);
        let res = await fetch(
            `${Constants.B2B_API}/organization/upload-multiple-product?organization_id=${organization_id}`,
            {
              method: 'POST',
              body: data
            }
          ).then(async(res)=>{
            let clean = true
            let response = await res.json()
              // if(response.success == "200"){
              //   setSuccess('File uploaded successfully')
              //   setFile()
              //   setFileName('')
              // } else setErrorMessage('Upload error')
              response.messages.map(m=>{
                if(m.message == "Product added successfully"){
                  return
                } else if(m.message !== "Product added successfully") {
                  setErrorMessage('Error in Data')
                  clean = false
                }
              })

              if(clean == true){
                setSuccess('File uploaded successfully')
                setFile()
                setFileName('')
              }
          })
          .catch(()=>{
            console.log('fetch request not made')
          })
    }
    
    const _pickRetail = async () => {
      let result = file
      const data = new FormData();
      let organization_id =  await AsyncStorage.getItem('organizationID');
      data.append('files', result.file);
      let res = await fetch(
          `http://127.0.0.1:5000/organization/upload-analytics?organization_id=${organization_id}`,
          {
            method: 'POST',
            body: data
          }
        ).then(async(res)=>{
          let response = await res.json()
            if(response.success == "200"){
              setSuccess('File uploaded successfully')
              setFile()
              setFileName('')
            } else setErrorMessage('Upload error')
        })
        .catch(()=>{
          console.log('fetch request not made')
          setErrorMessage('Error: File was not uploaded')
        })
    }

    const _pickRevenue = async () => {
      let result = file
      const data = new FormData();
      let organization_id =  await AsyncStorage.getItem('organizationID');
      data.append('files', result.file);
      let res = await fetch(
          `http://127.0.0.1:5000/organization/upload-revenue?organization_id=${organization_id}`,
          {
            method: 'POST',
            body: data
          }
        ).then(async(res)=>{
          let response = await res.json()
            if(response.success == "200"){
              setSuccess('File uploaded successfully')
              setFile()
              setFileName('')
            } else setErrorMessage('Upload error')
        })
        .catch(()=>{
          console.log('fetch request not made')
          setErrorMessage('Error: File was not uploaded')
        })
    }

    const _pickSubscription = async () => {
      let result = file
      const data = new FormData();
      let organization_id =  await AsyncStorage.getItem('organizationID');
      data.append('files', result.file);
      let res = await fetch(
          `${Constants.B2B_API}/organization/upload-analytics-subscription?organization_id=${organization_id}`,
          {
            method: 'POST',
            body: data
          }
        ).then(async(res)=>{
          let response = await res.json()
            if(response.success == "200"){
              setSuccess('File uploaded successfully')
              setFile()
              setFileName('')
            } else setErrorMessage('Upload error')
        })
        .catch(()=>{
          console.log('fetch request not made')
          setErrorMessage('Error: File was not uploaded')
        })
        // let responseJson = await res.json();
        // console.log('here',responseJson)
        // if(responseJson.success == "200"){
        //   setSuccess('File uploaded successfully')
        //   setFile()
        //   setFileName('')
        // } else return
    }

    const findType = (type) => {
        if(type === 'product') {
            return _pickDocument();
        } else if(type === 'retail-data-uploader') {
          return _pickRetail();
        } else if(type === 'subscription-data-uploader') {
          return _pickSubscription();
        } else if(type === 'revenue-forecasting-uploader') {
          return _pickRevenue();
        }
    }
  

    return (
      <View style={{ 
      width: Dimensions.get('window').width * 0.8,
      maxWidth: 700,
      margin:30, 
      height:300, 
      borderStyle: 'dashed',
      borderColor: Color.DarkGreen,
      backgroundColor:'#fff', 
      borderRadius:10,
      borderRightWidth:1, 
      borderLeftWidth:1, 
      borderBottomWidth:1, 
      borderTopWidth:1,
      justifyContent: (file ? 'flex-start' : 'center'),
      alignItems:(file ? 'flex-start' : 'center'),      
    }}>
          <View>  
          {fileName.length == 0 ? 
          (<View style={{width: 150, alignItems: 'center', justifyContent: 'center'}}>
          <Button onPress={() => getFile()}
            backgroundColor={Color.DarkGreen}
            color={Color.DarkGreen}
            title="Add File">
            </Button>
            <Text style={{ fontSize: 10, color: 'black'}}> (file format .csv or .xlsx)</Text>
          </View> ) :
          (<View style={{justifyContent: 'space-between'}}>
            <TouchableOpacity style={styles.card} activeOpacity={.6}>
              <Text style={{maxWidth: '60%', fontWeight: '500'}} lineBreakMode='tail' numberOfLines={1}>{fileName}</Text>
              <View style={{flexDirection: 'row'}}>
              <Text style={{fontWeight: '500'}}>{fileSize} bytes</Text>
              <TouchableOpacity onPress={()=>removeFile()}>
                <MaterialCommunityIcons name="delete" size={20} color="black" style={{marginLeft: 20}} />
              </TouchableOpacity>
              </View>
            </TouchableOpacity>
          </View>)
          
        }
            </View>
            {file && 
            <View style={{alignSelf: 'center', marginTop: 180}}>
              <Button 
            onPress={() => findType(type)}
            backgroundColor={Color.DarkGreen}
            color={Color.DarkGreen}
            title="Upload File">
            </Button>
            </View>
            }
            {!file && success.length > 0 && 
            <View style={{backgroundColor: '#e3e3e3', position: 'absolute', bottom: 200, padding: 10, borderRadius: 8}}>
            <Text>{success}</Text>
            </View>}
            {errorMessage.length > 0 && 
            <View style={{backgroundColor: '#e3e3e3', bottom: 200, padding: 10, borderRadius: 8, borderWidth: 1, borderColor: 'red', alignSelf: 'center', opacity: .8}}>
            <Text>{errorMessage}</Text>
            </View>}
        </View>
    );
    
  };

  export default Upload;

  const styles = StyleSheet.create({
    card:{
      flexDirection: 'row', 
      padding: 10, 
      justifyContent: 'space-between', 
      backgroundColor: 'white', 
      borderRadius: 4, 
      borderWidth: 1, 
      borderColor: '#e3e3e3',
      margin: 10, 
      maxWidth: 350, 
      // maxWidth: '90%',
      minHeight: 50,
      alignItems: 'center'
    }
  })
