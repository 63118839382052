import React, { Component } from 'react'
import {Helmet} from "react-helmet";

export class mediAssistChat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            random: Math.random(),
            script: '',
            appConfig:''
          }
      }

      async componentWillMount() {
        this.getAppConfig()
        this.getClient()
    }

    // async componentDidUpdate(){
    //     console.log(this.state.script)
    // }

    async getAppConfig(){
        let res = await fetch(
            `https://storage.googleapis.com/cxorgapp-fe-assets/appConfigs/mediAssistChat.json?=${this.state.random}`,
            {
              method: 'GET',
            }
          ).then(async(res)=>{
              let response = await res.text()
              if(response){
                  // let randomizedScript = response
                  this.setState({appConfig: response})
              }
          })
          .catch(()=>{
            console.log('fetch request not made')
          })
    }

      async getClient(){
          let res = await fetch(
              `https://storage.googleapis.com/cxorgapp-fe-assets/client.js?=${this.state.random}`,
              {
                method: 'GET',
              }
            ).then(async(res)=>{
                let response = await res.text()
                if(response){
                    // let randomizedScript = response
                    this.setState({script: response})
                }
            })
            .catch(()=>{
              console.log('fetch request not made')
            })
      }

    render () {
        return (
            <div>
                <h3 style={{color: 'red', fontSize: 12, marginLeft: 20}}>Medi Assist</h3>
               {this.state.script && 
               <div id="cxspherechat" className="cxspherechat" style={{position:'fixed', bottom: 0, right: 0}}>
                    <Helmet>
                    <script type='module' name='cxspherechat' async defer >{this.state.script}</script>
                    <script>
                    {` appConfig = ${this.state.appConfig}`}
                    </script>
                    </Helmet>
                </div>}
            </div>
        );
      }
}

export default mediAssistChat