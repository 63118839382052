import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SafeAreaView, StyleSheet, Alert, Text, View, Image, Dimensions, TouchableOpacity, Platform, Linking, ScrollView } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Color } from './../utils/color';
import { Constants } from './../utils/constants';
import {Analytics } from '../components/Analytics';
import MobileHeader from './../components/MobileHeader';
import MenuSettings from '../components/MenuSettings';
import SideMenu from './../components/SideMenu';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import Label from './../components/Label';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { WebView } from 'react-native-webview';
import Iframe from 'react-iframe'

export default class UploaderScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            myWidth: Platform.select({
                web: Dimensions.get("window").width - 150,
                default: Dimensions.get("window").width,
            }),
            isOpen: false,
            originalWidth: Dimensions.get("window").width,
            fullName: "",
            arrEngage: [],
            openFrame: false,
            openFrameurl: ""
        }
    }
    async componentWillMount() {

        const userToken = await AsyncStorage.getItem('userToken');
        if (userToken == "null") {
            this.props.navigation.navigate('LoginScreen');
        } else {
            this.getEngageList()
        }

        const fullName = await AsyncStorage.getItem('fullName');
        this.setState({
            fullName: fullName
        })
    }

    getEngageList = async () => {

        const userToken = await AsyncStorage.getItem('userToken');
        const organizationID = await AsyncStorage.getItem('organizationID');


        try {

            let url = `${Constants.BASE_URL}${Constants.ORGANIZATION}/${organizationID}/data`

            let response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                },
            });
            let responseJson = await response.json();

            if (typeof responseJson != "undefined") {
                if (typeof responseJson.engage_types != "undefined") {
                    this.setState({
                        arrEngage: []
                    }, () => {
                        this.state.arrEngage.push({
                            "name": "Retail Data Uploader",
                            "application_name": "Retail Data Uploader",
                            "url": "RetailDataUploader"
                        },{
                            "name": "Subscription Data Uploader",
                            "application_name": "Subscription Data Uploader",
                            "url": "SubscriptionDataUploader"
                        })
                        this.setState({})
                    })
                }
            } else {
                if (Platform.OS === 'web') {
                    alert(responseJson.message)
                } else {
                    Alert.alert("CXSphere", responseJson.message)
                }
            }

        } catch (error) {
            console.error("====== error =====");
        }
    }

    goTo(url, isOpenFrame) {

        if (!isOpenFrame) {
            this.setState({
                openFrame: false
            })
            if(url == "RetailDataUploader") {
                this.props.navigation.navigate("RetailDataUploader")
            } else if(url == "SubscriptionDataUploader") {
                    this.props.navigation.navigate("SubscriptionDataUploader")
            } else if(url == "Invoice") {
                    this.props.navigation.navigate("Invoice")                    
            } 
        } else {
            this.setState({
                openFrame: true,
                openFrameurl: url
            })
        }

    };
    
    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })
        if (width >= 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }
        this.setState(() => ({ isWide: width >= 767 }));

    };
    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }

        return (
            <View onLayout={this._handleLayout} style={styles.Container}>
                <Analytics />
                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Engage" />}
                <View style={{ flexDirection: 'row', flex: 1, }}>
                    {this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}
                    <View style={[styles.containBox, { width: this.state.myWidth }]}>
                        {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                            width: this.state.myWidth,
                            justifyContent: "center",
                            alignItems: 'center'
                        }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'), textAlign: 'center',
                                alignSelf: 'center',
                            }]}>Uploaders</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../assets/images/small_down.png")} />
                                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("./../assets/images/profile_img.png")} /> */}
                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>
                            </TouchableOpacity>
                        </View>}
                        <MenuSettings 
                            {...this.props} 
                            isOpen={this.state.isOpen} 
                            myWidth={this.state.myWidth} 
                            onClick={() =>this.setState({ isOpen: false})} 
                        />
                        {this.state.openFrame && <View style={{ height: 45, width: '100%', alignItems: "center", justifyContent: "center" }}>

                            <TouchableOpacity style={{ alignSelf: 'flex-start', textAlign: "center" }} onPress={() => {
                                this.setState({
                                    openFrame: false
                                })
                            }}>
                                <Label ml={20} sigleLine={false} numberOfLines={1} font15Large Barlow_SemiBold color={Color.DarkGreen} >{"Back"}</Label>

                            </TouchableOpacity>

                        </View>}

                        <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false} scrollEnabled={!this.state.openFrame}>

                            {!this.state.openFrame && <View style={{
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                width: "100%",
                                alignItems: (this.state.originalWidth < 767 ? "center" : 'flex-start'),
                                justifyContent: (this.state.originalWidth < 767 ? "flex-start" : 'flex-start'),
                                paddingTop: 10,
                                paddingBottom: 10,
                                paddingLeft: (this.state.originalWidth < 767 ? 0 : 10)
                            }}>

                                {!this.state.openFrame &&
                                    this.state.arrEngage.map((item) => {

                                        let image = require("./../assets/images/support.png");

                                        if (item.name.toUpperCase().includes("retain".toUpperCase())) {
                                            image = require("./../assets/images/ratain.png");
                                        } else if (item.name.toUpperCase().includes("support".toUpperCase())) {
                                            image = require("./../assets/images/support.png");
                                        } else if (item.name.toUpperCase().includes("manage relation".toUpperCase())) {
                                            image = require("./../assets/images/manage_relation.png");
                                        } else if (item.name.toUpperCase().includes("insights".toUpperCase())) {
                                            image = require("./../assets/images/insights.png");
                                        } else if(item.name.toUpperCase().includes("Data Uploader".toUpperCase())) {
                                            image = require("./../assets/images/ratain.png");
                                        }


                                        return (
                                            <TouchableOpacity onPress={() => this.goTo(item.url, item.open_iframe)} style={[styles.boxColumn, { width: (this.state.originalWidth < 767 ? this.state.myWidth * 0.43 : 170), height: (this.state.originalWidth < 767 ? this.state.myWidth * 0.43 : 170), alignItems: "center", justifyContent: "center" }]}>
                                                <Image style={styles.boxIcon} source={image}
                                                />
                                                <Label ml={10} mr={10} mt={15} sigleLine={false} numberOfLines={2} style={{ alignSelf: 'center', textAlign: "center" }} font18Large Barlow_SemiBold color={Color.DarkGreen} >{item.name}</Label>
                                            </TouchableOpacity>
                                        )
                                    })
                                }

                            </View>}

                            {/* {this.state.openFrame && <View style={{ height: 45, width: '100%', alignItems: "center", justifyContent: "center" }}>

                                <TouchableOpacity style={{ alignSelf: 'flex-start', textAlign: "center" }} onPress={() => {
                                    this.setState({
                                        openFrame: false
                                    })
                                }}>
                                    <Label ml={20} sigleLine={false} numberOfLines={1} font15Large Barlow_SemiBold color={Color.DarkGreen} >{"Back"}</Label>

                                </TouchableOpacity>

                            </View>} */}

                            {this.state.openFrame && <View style={{
                                width: "100%",
                                paddingTop: 0,
                                paddingBottom: 10,
                                paddingLeft: (this.state.originalWidth < 767 ? 0 : 0),
                                height: (this.state.originalWidth < 767 ? Dimensions.get("window").height - 120 : Dimensions.get("window").height - 145)
                            }}>


                                <View style={{
                                    width: this.state.myWidth,
                                    height: (this.state.originalWidth < 767 ? '100%' : Dimensions.get("window").height - 145),
                                    flexDirection: "column"
                                }}>

                                    {/* height={(this.state.originalWidth < 767 ? Dimensions.get("window").height - 120 : Dimensions.get("window").height - 150)} */}

                                    {(Platform.OS !== 'ios' && Platform.OS != 'android') && <Iframe
                                        onload="setInterval(document.getElementById('testFrame').contentWindow.location.reload(), 100);"
                                        url={this.state.openFrameurl}
                                        position="fixed"
                                        width={this.state.myWidth}
                                        id="myId"
                                        className="myClassname"
                                        height={"100%"}
                                        styles={{ marginTop: '145px' }} />}

                                    {(Platform.OS == 'ios' || Platform.OS == 'android') && <WebView
                                        useWebKit={true}
                                        javaScriptEnabled={true}
                                        source={{ uri: this.state.openFrameurl }}
                                        style={{ width: '100%', height: '100%' }}
                                        startInLoadingState={true}
                                    />}

                                </View>
                            </View>}

                        </ScrollView>

                    </View>

                </View>
            </View >

        )
    }
}

UploaderScreen.navigationOptions = {
    header: null,
};
const base = StyleSheet.create({
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        //width: Dimensions.get("window").width - 150,
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 999999,
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },

    boxIcon: {
        height: 62,
        width: 62,
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        width: Dimensions.get("window").width - 150,
    },
    boxColumn: {
        backgroundColor: Color.White,
        marginLeft: 10,
        marginRight: 10,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.4,
        shadowRadius: 5,
        elevation: 1,
        alignItems: 'center',
        justifyContent: "center"
    }



});
const styles = createStyles(
    base,
    maxWidth(991, {
        title: {
            fontSize: 36,
            color: Color.DarkGreen,
            textAlign: 'left',
            marginLeft: 10,
            width: '100%',
            marginTop: 20,
        },


        boxIcon: {
            height: 62,
            width: 62,
        },
    }),
    maxWidth(767, {
        Container: {
            flex: 1,
            backgroundColor: Color.WhiteLight,
        },
        containBox: {
            //width: Dimensions.get("window").width,
        },

        boxIcon: {
            height: 62,
            width: 62,
        },
        containBox: {
            width: '100%',
        }

    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        },
    })
);