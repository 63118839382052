import React, { useState, useEffect } from 'react';
import {FlatList, Text, View, TouchableOpacity} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Color } from '../../utils/color';
import { Constants } from './../../utils/constants';
import InformationResponse from './InformationResponse';
import { Analytics } from '../../components/Analytics';

export default function InformationRequestList(props) {
    const [informationResponse, setInformationResponse] = useState({show: false, informationRequestId: null})
    const handleInformationRequestDetails = (_informationRequestId) => {
        console.log("Inside setInformationResponse with informationRequestId: ", _informationRequestId);
        setInformationResponse({
            show: (_informationRequestId !== null),
            informationRequestId: _informationRequestId
        });
    }
    console.log("Value of informationResponse: " + JSON.stringify(informationResponse));
    return (
        <>
        <Analytics />
        {informationResponse.show && <InformationResponse {...props} informationRequestId={informationResponse.informationRequestId} handleInformationRequestDetails ={handleInformationRequestDetails} />}
        {!informationResponse.show && <InformationRequestHeader {...props} />}
        {!informationResponse.show && <InformationRequestTable {...props} handleInformationRequestDetails={handleInformationRequestDetails} />}
        </>
    )
}
function InformationRequestHeader(props) {
    return (
        <View style={{ flexDirection: 'row', margin:5, height:65 }}>
            <TouchableOpacity accessibilityRole="button"
                style={{ borderRadius: 5, alignItems:"center", paddingTop:4, backgroundColor: Color.DarkGreen, position: 'absolute', right: 15, top: 5, height:35, width:170 }}
                onPress={() => props.navigation.navigate("AddInformationRequest")}>
                <Text style={{fontSize:16, fontWeight:'bold', color: Color.White}}>Make a new request</Text>
            </TouchableOpacity>
        </View>
    );
}
function InformationRequestTable(props) {
    const [informationRequests, setInformationRequests] = useState([]);

    useEffect(() => {
        fetchInformationRequests().then(res => setInformationRequests(res));
    }, []);

    return (
        <FlatList data ={informationRequests} 
        ListHeaderComponent={InformationRequestTableHeader} 
        renderItem={({item}) => <InformationRequestRow item={item} {...props} />}/>
    );
}
export function ColumnHeader(props) {
    return (
        <Text style={{fontSize:18, fontWeight:'bold',flex:1, color: Color.DarkGreen }}>{props.title}</Text>
    );
}
function InformationRequestTableHeader(props) {
    return (
        <View style={{flexDirection:'row', margin:5}}>
            <ColumnHeader title="Title" />
            <ColumnHeader title="Sample Size" />
            <ColumnHeader title="Request Date" />
            <ColumnHeader title="End Date" />
            <ColumnHeader title="Actions" />
        </View>
    );
}
export function TableCell(props) {
    return (
        <Text style={{flex:1,fontSize:15}}>{props.text}</Text>
    )
}
function InformationRequestRow(props) {
    return (
        <View style={{flexDirection:'row',margin:5}} key={props.item.id}>
            <TableCell text={props.item.title} />
            <TableCell text={props.item.sampleSize} />
            <TableCell text={props.item.requestDate} />
            <TableCell text={props.item.endDate} />
            <TouchableOpacity style={{flex:1, borderWidth:1, height:25, width:20, alignItems:"center", borderRadius: 3, paddingTop:3,}} onPress={() => props.handleInformationRequestDetails(props.item.id)} accessibilityRole="button">
                <Text style={{fontSize:15, color:Color.DarkGreen}}>Details</Text>
            </TouchableOpacity>
        </View>
    );
}

const fetchInformationRequests = async (accessToken) => {
    const organizationID = await AsyncStorage.getItem('organizationID');
    const userToken = await AsyncStorage.getItem('userToken');

    let url = `${Constants.BASE_URL}/organization/${organizationID}/information-request`;
    let response = await makeFetchCall(url, userToken);
    let responseJson = await response.json();
    console.log("Finished fetching the informationRequests.")
    return responseJson;
}

export const makeFetchCall = async (url, accessToken) => {
    let response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        },
    });
    return response;
}